import "./careers.scss";
import { Link } from "react-router-dom";

const Careers = () => {
  document.title = "Careers";
  return (
    <div style={{ marginTop: "110px", marginBottom: "50px" }}>
      <div className="container-lg">
        <h1 className="careers-title">
          Get the most exciting jobs <br /> from all over the
          <span className="adateck-careers">&nbsp;Adateck.</span>
        </h1>
        <div className="all-jobs">
          <Link to="/job" className="job-container">
            <h2 className="career-job-title">Backend Engineer - PHP</h2>
            <div className="job-bottom-container">
              <div className="job-location">
                <i
                  className="fa-solid fa-location-dot"
                  style={{ marginRight: "5px" }}
                ></i>{" "}
                Islamabad, Lahore, Karachi
              </div>{" "}
              <div className="job-category">Technology</div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Careers;
