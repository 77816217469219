import { Link } from "react-router-dom";
import "./header.scss";
import { useLocation } from "react-router-dom";
import { useState } from "react";

const Header = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark menu shadow fixed-top">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src="images/logo-white.png" alt="logo image" width="30%" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                className={
                  location.pathname === "/" ? "nav-link active" : "nav-link"
                }
                aria-current="page"
                to="/"
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  location.pathname === "/about-us"
                    ? "nav-link active"
                    : "nav-link"
                }
                to="about-us"
              >
                About{" "}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  location.pathname === "/our-portfolio" ||
                  location.pathname === "/cvvlogs" ||
                  location.pathname === "/previsitreview" ||
                  location.pathname === "/express-triage" ||
                  location.pathname === "/qubic-lab"
                    ? "nav-link active"
                    : "nav-link"
                }
                to="our-portfolio"
              >
                Portfolio{" "}
              </Link>
            </li>

            <li
              className="nav-item dropdown"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Link
                className={`nav-link dropdown-toggle ${
                  location.pathname === "/services" ||
                  location.pathname === "/graphics-design" ||
                  location.pathname === "/web-development" ||
                  location.pathname === "/digital-marketing" ||
                  location.pathname === "/hire-remote-developer" ||
                  location.pathname === "/hire-remote-team" ||
                  location.pathname === "/ui-ux-development" ||
                  location.pathname === "/mobile-app-development" ||
                  location.pathname === "/wordpress-design" ||
                  location.pathname === "/data-science"
                    ? "nav-link active"
                    : "nav-link"
                }`}
                to="services"
                id="navbarDarkDropdownMenuLink"
                // role="button"
                // data-bs-toggle="dropdown"
                aria-expanded={isOpen ? "true" : "false"}
              >
                Services
              </Link>

              <ul
                className={`dropdown-menu ${isOpen ? "show" : ""}`}
                aria-labelledby="navbarDarkDropdownMenuLink"
              >
                {" "}
                <li>
                  <Link class="dropdown-item" to="/graphics-design">
                    Graphics Design
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/web-development">
                    Web Development{" "}
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/ui-ux-development">
                    UI/UX Development{" "}
                  </Link>
                </li>{" "}
                <li>
                  <Link class="dropdown-item" to="/mobile-app-development">
                    Mobile App Development{" "}
                  </Link>
                </li>{" "}
                <li>
                  <Link class="dropdown-item" to="/wordpress-design">
                    Wordpress Design{" "}
                  </Link>
                </li>{" "}
                <li>
                  <Link class="dropdown-item" to="/digital-marketing">
                    Digital Marketing{" "}
                  </Link>
                </li>{" "}
                <li>
                  <Link class="dropdown-item" to="/data-science">
                    Data Science{" "}
                  </Link>
                </li>{" "}
                <li>
                  <Link class="dropdown-item" to="/hire-remote-developer">
                    Hire Remote Developer{" "}
                  </Link>
                </li>{" "}
                <li>
                  <Link class="dropdown-item" to="/hire-remote-team">
                    Hire Remote Team{" "}
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <Link
                className={
                  location.pathname === "/blogs" ||
                  location.pathname === "/blog"
                    ? "nav-link active"
                    : "nav-link"
                }
                to="blogs"
              >
                Blogs
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  location.pathname === "/careers"
                    ? "nav-link active"
                    : "nav-link"
                }
                to="careers"
              >
                Careers
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  location.pathname === "/contact-us"
                    ? "nav-link active"
                    : "nav-link"
                }
                to="contact-us"
              >
                contact
              </Link>
            </li>
          </ul>
          {/* <button type="button" className="rounded-pill btn-rounded">
            +1728365413
            <span>
              <i className="fas fa-phone-alt"></i>
            </span>
          </button> */}
        </div>
      </div>
    </nav>
  );
};

export default Header;
