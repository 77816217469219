import "./portfolioWebs.scss";
import { Helmet } from "react-helmet";

const PrevisitReview = () => {
  return (
    <>
      <Helmet>
        <title>
          PrevisitReview | Expert App Development Solutions | ADATECK{" "}
        </title>
        {/* <meta
          name="description"
          content="Learn more about ADATECK, a leader in backend and frontend app development. Our dedicated team of professionals is committed to delivering cutting-edge, customized solutions for your business. Explore our mission, values, and the expertise that sets us apart in the tech industry. Contact us to elevate your digital presence with our innovative app development services."
        /> */}
      </Helmet>
      <div style={{ marginTop: "140px", marginBottom: "100px" }}>
        <div className="row text-center mb-4">
          <h1 className="display-3 fw-bold text-uppercase">PrevisitReview</h1>
          <div className="heading-line"></div>
        </div>

        <div className="container-lg">
          <div className="row align-items-center snipcss-ZKJbZ">
            <div className="col-12 col-lg-6 ">
              <div className="who-we-contant mt-s">
                <div className="promo-section">
                  <h3 className="special-head ">PREVISIT REVIEW!</h3>
                </div>
                <h4 className="d-blue fadeInUp" data-wow-delay="0.3s">
                  Streamlined Healthcare Management{" "}
                </h4>
                <div className="list-wrap align-items-center">
                  <div className="row">
                    <p>
                      Streamlined Healthcare Management Our project is a web
                      platform designed to enhance the patient experience and
                      streamline the healthcare process. This innovative
                      solution allows patients to create profiles and submit
                      their health issues digitally before their appointments,
                      eliminating the need for cumbersome paperwork at the
                      doctor's office. Key Features: Patient Profiles: Patients
                      can create detailed profiles and digitally submit their
                      health issues prior to their appointments, ensuring
                      doctors have all necessary information in advance.
                      Appointment Scheduling: Patients can easily schedule
                      appointments with their healthcare providers through the
                      platform, reducing the need for lengthy phone calls and
                      simplifying the booking process. Previsit Review: By
                      filling out and submitting forms from home, patients save
                      time and avoid the hassle of completing paperwork in the
                      waiting room. Provider Portal: Healthcare providers have
                      access to a dedicated portal where they can manage
                      appointments and review patient information ahead of
                      visits, allowing for more efficient and prepared
                      consultations. Our Previsit Review software transforms the
                      healthcare experience, making it more efficient,
                      patient-friendly, and focused on well-being. By digitizing
                      the pre-appointment process, we prioritize patient
                      convenience and enhance the overall quality of care.
                    </p>
                    <a
                      href="https://www.previsitreview.com"
                      className="link-style"
                      target="_blank"
                    >
                      Visit PREVISIT REVIEW
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div
                className="welcome-meter about-sec-wrapper wow fadeInUp style-Kojfk"
                data-wow-delay="0.4s"
                id="style-Kojfk"
              >
                <img
                  src="images/previsit-review.jpg"
                  className="about-i"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrevisitReview;
