import "./about.scss";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
      <Helmet>
        <title>About Us | Expert App Development Solutions | ADATECK </title>
        <meta
          name="description"
          content="Learn more about ADATECK, a leader in backend and frontend app development. Our dedicated team of professionals is committed to delivering cutting-edge, customized solutions for your business. Explore our mission, values, and the expertise that sets us apart in the tech industry. Contact us to elevate your digital presence with our innovative app development services."
        />
      </Helmet>

      <div style={{ marginTop: "140px" }}>
        <div className="row text-center ">
          <h1 className="display-3 fw-bold text-uppercase">About Us</h1>
          <div className="heading-line"></div>
        </div>
        <div className="container-lg">
          <div className="row align-items-center snipcss-ZKJbZ">
            <div className="col-12 col-lg-6">
              <div
                className="welcome-meter about-sec-wrapper wow fadeInUp style-Kojfk"
                data-wow-delay="0.4s"
                id="style-Kojfk"
              >
                <img src="images/about-sec1.png" className="about-i" alt="" />
                <div className="article special box-shadow">
                  <img src="images/s55.png" className="mb-10" alt="" />
                  <div>
                    <h3 className="article__title w-text">Our Mission</h3>
                    <p className="g-text">
                      Our mission is to empower businesses with cutting-edge
                      technology solutions, driving their digital transformation
                      and enabling them to thrive in a rapidly evolving
                      tech-driven landscape.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 ">
              <div className="who-we-contant mt-s">
                <div className="promo-section">
                  <h3 className="special-head ">Welcome to Our Agency!</h3>
                </div>
                <h4 className="d-blue fadeInUp" data-wow-delay="0.3s">
                  IT Solutions with Experienced Staff of Engineers provide you
                  with optimal IT experience
                </h4>
                <div className="list-wrap align-items-center">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="side-feature-list-item">
                        <i
                          class="fa-solid fa-circle-check"
                          style={{
                            color: "#a230ef",
                            fontSize: "25px",
                            marginRight: "17px",
                          }}
                        ></i>
                        <div className="foot-c-info">
                          IT Infrastructure setup and maintenance.
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="side-feature-list-item">
                        <i
                          class="fa-solid fa-circle-check"
                          style={{
                            color: "#a230ef",
                            fontSize: "25px",
                            marginRight: "17px",
                          }}
                        ></i>
                        <div className="foot-c-info">
                          Custom software development services.
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="side-feature-list-item">
                        <i
                          class="fa-solid fa-circle-check"
                          style={{
                            color: "#a230ef",
                            fontSize: "25px",
                            marginRight: "17px",
                          }}
                        ></i>
                        <div className="foot-c-info">
                          Responsive web design and development.
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="side-feature-list-item">
                        <i
                          class="fa-solid fa-circle-check"
                          style={{
                            color: "#a230ef",
                            fontSize: "25px",
                            marginRight: "17px",
                          }}
                        ></i>
                        <div className="foot-c-info">
                          Secure e-commerce solutions.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
