import { Link } from "react-router-dom";

const Services = () => {
  document.title = "Services";

  return (
    <div style={{ marginTop: "140px" }}>
      {" "}
      <section id="services" className="services">
        <div className="container">
          <div className="row text-center">
            <h1 className="display-3 fw-bold">Our Services</h1>
            <div className="heading-line mb-1"></div>
          </div>
          {/* <!-- START THE DESCRIPTION CONTENT  --> */}

          <div className="row pt-2 pb-2 mt-0 mb-3">
            <div className="col-md-6 border-right">
              <div className="bg-white p-3">
                <h2 className="fw-bold text-capitalize text-center">
                  Our Services Range From Initial Design To Deployment Anywhere
                  Anytime
                </h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="bg-white p-4 text-start">
                <p className="fw-light">
                  Adateck is a dynamic software development and digital
                  solutions company at the forefront of technological
                  innovation. With a passion for crafting cutting-edge software
                  and digital strategies, they empower businesses to thrive in
                  the digital age through tailored solutions that drive growth
                  and efficiency.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- START THE CONTENT FOR THE SERVICES  --> */}
        <div className="container">
          {/* <!-- START THE MARKETING CONTENT  --> */}
          <div class="row">
            <div
              class="col-xl-4 col-md-6 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div class="icon-box">
                <div class="icon">
                  <i class="fa-solid fa-images"></i>{" "}
                </div>
                <h4>
                  <Link to="/graphics-design">Graphics Design</Link>
                </h4>
                <p>
                  Our talented graphic designers bring ideas to life, creating
                  visually captivating designs that resonate with your brand
                  identity and engage your target audience across various
                  platforms.
                </p>
              </div>
            </div>

            <div
              class="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div class="icon-box">
                <div class="icon">
                  <i class="fa-brands fa-edge"></i>{" "}
                </div>
                <h4>
                  <Link to="/web-development">Web Development</Link>
                </h4>
                <p>
                  Our team of skilled developers crafts dynamic and
                  user-friendly websites, leveraging the latest technologies to
                  ensure seamless navigation, responsive design, and optimal
                  performance.
                </p>
              </div>
            </div>

            <div
              class="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <div class="icon-box">
                <div class="icon">
                  <i class="fa-solid fa-object-group"></i>{" "}
                </div>
                <h4>
                  <Link to="/ui-ux-development">UI/UX Development</Link>
                </h4>
                <p>
                  We specialize in crafting intuitive and immersive user
                  interfaces, focusing on delivering exceptional user
                  experiences that enhance customer satisfaction and drive
                  conversions.
                </p>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div
              class="col-xl-4 col-md-6 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div class="icon-box">
                <div class="icon">
                  <i class="fa-solid fa-mobile"></i>{" "}
                </div>
                <h4>
                  <Link to="/mobile-app-development">
                    Mobile App Development
                  </Link>
                </h4>
                <p>
                  Our mobile app development team builds innovative and
                  feature-rich applications for iOS and Android platforms,
                  enabling you to reach and engage with your customers on their
                  preferred devices.
                </p>
              </div>
            </div>

            <div
              class="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div class="icon-box">
                <div class="icon">
                  <i class="fa-brands fa-wordpress"></i>{" "}
                </div>
                <h4>
                  <Link to="/wordpress-design">Wordpress Design</Link>
                </h4>
                <p>
                  We offer customized WordPress solutions, combining
                  eye-catching design elements with powerful functionality to
                  create websites that are easy to manage and highly scalable.
                </p>
              </div>
            </div>

            <div
              class="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <div class="icon-box">
                <div class="icon">
                  <i class="fa-solid fa-chart-simple"></i>{" "}
                </div>
                <h4>
                  <Link to="/digital-marketing">Digital Marketing</Link>
                </h4>
                <p>
                  Our digital marketing experts utilize data-driven strategies
                  to boost your online presence, increase brand visibility, and
                  drive targeted traffic to your website, ensuring maximum ROI
                  and business growth.
                </p>
              </div>
            </div>
          </div>{" "}
          <div class="row mt-3">
            <div
              class="col-xl-4 col-md-6 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div class="icon-box">
                <div class="icon">
                  <i class="fa-solid fa-database"></i>{" "}
                </div>
                <h4>
                  <Link to="/data-science">Data Science</Link>
                </h4>
                <p>
                  Our data science team leverages advanced analytics and machine
                  learning to extract actionable insights from complex datasets,
                  driving innovation and informing decision-making for our
                  company's growth.
                </p>
              </div>
            </div>

            <div
              class="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div class="icon-box">
                <div class="icon">
                  <i class="fa-solid fa-user"></i>{" "}
                </div>
                <h4>
                  <Link to="/hire-remote-developer">Hire Remote Developer</Link>
                </h4>
                <p>
                  When you choose to hire a remote developer, you unlock a world
                  of talent and expertise without the limitations of
                  geographical boundaries. Our remote developers are highly
                  skilled professionals who can seamlessly integrate into your
                  team, offering their expertise in software development, web
                  development, and other IT domains. Enjoy the flexibility,
                  cost-effectiveness, and access to a diverse talent pool by
                  hiring remote developers who can work remotely and deliver
                  exceptional results.
                </p>
              </div>
            </div>

            <div
              class="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <div class="icon-box">
                <div class="icon">
                  <i class="fa-solid fa-user-group"></i>{" "}
                </div>
                <h4>
                  <Link to="/hire-remote-team">Hire Remote Team</Link>
                </h4>
                <p>
                  Building a remote team brings numerous benefits to your
                  organization. By assembling a remote team, you gain access to
                  a broader talent pool, including professionals with diverse
                  skill sets and backgrounds. This allows you to scale your team
                  according to project requirements, improve productivity, and
                  reduce costs associated with traditional in-house hiring.
                  Collaborate with our remote teams specialized in IT and
                  software development to drive innovation, achieve project
                  milestones, and meet your business goals effectively.
                </p>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
              <div className="services__content">
                <div className="icon d-block fas fa-paper-plane"></div>
                <h3 className="display-3--title mt-1">Graphic Design</h3>
                <p className="lh-lg">
                  Our talented graphic designers bring ideas to life, creating
                  visually captivating designs that resonate with your brand
                  identity and engage your target audience across various
                  platforms.
                </p>
             
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-end">
              <div className="services__pic">
                <img
                  src="images/services/service-1.png"
                  alt="marketing illustration"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-start">
              <div className="services__pic">
                <img
                  src="images/services/service-2.png"
                  alt="web development illustration"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
              <div className="services__content">
                <div className="icon d-block fas fa-code"></div>
                <h3 className="display-3--title mt-1">Web Development</h3>
                <p className="lh-lg">
                  Our team of skilled developers crafts dynamic and
                  user-friendly websites, leveraging the latest technologies to
                  ensure seamless navigation, responsive design, and optimal
                  performance.
                </p>
                
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
              <div className="services__content">
                <div className="icon d-block fas fa-cloud-upload-alt"></div>
                <h3 className="display-3--title mt-1">UI/UX Development</h3>
                <p className="lh-lg">
                  We specialize in crafting intuitive and immersive user
                  interfaces, focusing on delivering exceptional user
                  experiences that enhance customer satisfaction and drive
                  conversions.
                </p>
             
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-end">
              <div className="services__pic">
                <img
                  src="images/services/service-3.png"
                  alt="cloud hosting illustration"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-start">
              <div className="services__pic">
                <img
                  src="images/services/service-2.png"
                  alt="web development illustration"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
              <div className="services__content">
                <div className="icon d-block fas fa-code"></div>
                <h3 className="display-3--title mt-1">
                  Mobile App Development
                </h3>
                <p className="lh-lg">
                  Our mobile app development team builds innovative and
                  feature-rich applications for iOS and Android platforms,
                  enabling you to reach and engage with your customers on their
                  preferred devices.
                </p>
             
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
              <div className="services__content">
                <div className="icon d-block fas fa-paper-plane"></div>
                <h3 className="display-3--title mt-1">Wordpress Design</h3>
                <p className="lh-lg">
                  We offer customized WordPress solutions, combining
                  eye-catching design elements with powerful functionality to
                  create websites that are easy to manage and highly scalable.
                </p>
              
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-end">
              <div className="services__pic">
                <img
                  src="images/services/service-1.png"
                  alt="marketing illustration"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-start">
              <div className="services__pic">
                <img
                  src="images/services/service-2.png"
                  alt="web development illustration"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
              <div className="services__content">
                <div className="icon d-block fas fa-code"></div>
                <h3 className="display-3--title mt-1">Digital Marketing</h3>
                <p className="lh-lg">
                  Our digital marketing experts utilize data-driven strategies
                  to boost your online presence, increase brand visibility, and
                  drive targeted traffic to your website, ensuring maximum ROI
                  and business growth.
                </p>
              
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
              <div className="services__content">
                <div className="icon d-block fas fa-cloud-upload-alt"></div>
                <h3 className="display-3--title mt-1">Data Science</h3>
                <p className="lh-lg">
                  Our data science team leverages advanced analytics and machine
                  learning to extract actionable insights from complex datasets,
                  driving innovation and informing decision-making for our
                  company's growth.
                </p>
              
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-end">
              <div className="services__pic">
                <img
                  src="images/services/service-3.png"
                  alt="cloud hosting illustration"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-start">
              <div className="services__pic">
                <img
                  src="images/services/service-2.png"
                  alt="web development illustration"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
              <div className="services__content">
                <div className="icon d-block fas fa-code"></div>
                <h3 className="display-3--title mt-1">Hire Remote Developer</h3>
                <p className="lh-lg">
                  When you choose to hire a remote developer, you unlock a world
                  of talent and expertise without the limitations of
                  geographical boundaries. Our remote developers are highly
                  skilled professionals who can seamlessly integrate into your
                  team, offering their expertise in software development, web
                  development, and other IT domains. Enjoy the flexibility,
                  cost-effectiveness, and access to a diverse talent pool by
                  hiring remote developers who can work remotely and deliver
                  exceptional results.
                </p>
            
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
              <div className="services__content">
                <div className="icon d-block fas fa-cloud-upload-alt"></div>
                <h3 className="display-3--title mt-1">Hire Remote Team</h3>
                <p className="lh-lg">
                  Building a remote team brings numerous benefits to your
                  organization. By assembling a remote team, you gain access to
                  a broader talent pool, including professionals with diverse
                  skill sets and backgrounds. This allows you to scale your team
                  according to project requirements, improve productivity, and
                  reduce costs associated with traditional in-house hiring.
                  Collaborate with our remote teams specialized in IT and
                  software development to drive innovation, achieve project
                  milestones, and meet your business goals effectively.
                </p>
              
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-end">
              <div className="services__pic">
                <img
                  src="images/services/service-3.png"
                  alt="cloud hosting illustration"
                  className="img-fluid"
                />
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </div>
  );
};

export default Services;
