import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContactForm from "../../components/contact-form/contact-form";

const Contact = () => {
  const form = useRef();
  document.title = "Contact Us";
  const [isSubmitting, setIsSubmitting] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          if (result?.text === "OK") {
            toast.success("Email Sent Successfully!", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsSubmitting(false);

            form.current.reset();
          }
        },
        (error) => {
          toast.error(error.text, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false);
        }
      );
  };
  return (
    <div style={{ marginTop: "80px" }}>
      {" "}
      <ContactForm />
    </div>
  );
};

export default Contact;
