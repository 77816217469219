import "./portfolioWebs.scss";
import { Helmet } from "react-helmet";

const Cvvlogs = () => {
  return (
    <>
      <Helmet>
        <title>Cvvlogs | Expert App Development Solutions | ADATECK </title>
        {/* <meta
          name="description"
          content="Learn more about ADATECK, a leader in backend and frontend app development. Our dedicated team of professionals is committed to delivering cutting-edge, customized solutions for your business. Explore our mission, values, and the expertise that sets us apart in the tech industry. Contact us to elevate your digital presence with our innovative app development services."
        /> */}
      </Helmet>
      <div style={{ marginTop: "140px", marginBottom: "100px" }}>
        <div className="row text-center mb-4">
          <h1 className="display-3 fw-bold text-uppercase">Cvvlogs</h1>
          <div className="heading-line"></div>
        </div>
        <div className="container-lg" style={{ marginBottom: "100px" }}>
          <div className="row align-items-center snipcss-ZKJbZ">
            <div className="col-12 col-lg-6">
              <div
                className="welcome-meter about-sec-wrapper wow fadeInUp style-Kojfk"
                data-wow-delay="0.4s"
                id="style-Kojfk"
              >
                <img src="images/cvvlogs.jpg" className="about-i" alt="" />
              </div>
            </div>
            <div className="col-12 col-lg-6 ">
              <div className="who-we-contant mt-s">
                <div className="promo-section">
                  <h3 className="special-head ">CVVLOGS!</h3>
                </div>
                <h4 className="d-blue fadeInUp" data-wow-delay="0.3s">
                  Your Job Application With A Video Resume
                </h4>
                <div className="list-wrap align-items-center">
                  <div className="row">
                    <p>
                      Health Sector Job Portal Our project is an innovative web
                      platform and mobile application designed to revolutionize
                      the job search process in the health sector. This
                      comprehensive job portal caters specifically to job
                      opportunities for doctors, nurses, and trainees. Key
                      Features: Admin Job Posting: Administrators can easily
                      post job openings, ensuring a steady flow of opportunities
                      in the healthcare field. Unique Application Process:
                      Applicants can submit their resumes in a unique way by
                      recording a short video introduction in addition to
                      uploading their traditional PDF resume. This feature
                      allows candidates to showcase their personalities and
                      communication skills, providing a more holistic view of
                      their qualifications. Employer Review: Employers can view
                      both the video introductions and the PDF resumes of
                      applicants, making it easier to select the most suitable
                      candidates. This dual-format application process helps
                      employers make more informed hiring decisions. Our job
                      portal aims to bridge the gap between healthcare
                      professionals and employers, making the recruitment
                      process more dynamic, engaging, and effective.
                    </p>
                    <a
                      href="https://www.cvvlogs.com"
                      className="link-style"
                      target="_blank"
                    >
                      Visit CVVLOGS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cvvlogs;
