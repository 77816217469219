import React from "react";
import { Helmet } from "react-helmet";

const GraphicsDesign = () => {
  return (
    <>
      {" "}
      <Helmet>
        <title>Professional Graphic Design Services | ADATECK </title>
        <meta
          name="description"
          content="Enhance your brand with ADATECK's expert graphic design services. Our talented designers create stunning visuals that captivate and engage your audience. From logos to marketing materials, we deliver innovative and customized designs to elevate your business identity. Contact us today to transform your vision into reality with our top-notch graphic design solutions."
        />
      </Helmet>
      <div style={{ marginTop: "110px", marginBottom: "50px" }}>
        <section className="py-5">
          <div className="container">
            <div className="row text-center">
              <h1 className="display-3 fw-bold text-capitalize">
                Graphics Design
              </h1>
              <div className="heading-line"></div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <h3>Logo Design</h3>
                <p>
                  Our graphic design team specializes in creating unique and
                  memorable logos that effectively represent your brand
                  identity. We focus on creating designs that resonate with your
                  target audience and leave a lasting impression.
                </p>
              </div>
              <div className="col-lg-6">
                <h3>Brand Identity</h3>
                <p>
                  We help businesses establish a strong brand identity through
                  cohesive design elements such as color schemes, typography,
                  and imagery. Our goal is to create visual assets that convey
                  your brand values and personality consistently across all
                  channels.
                </p>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-6">
                <h3>Marketing Collateral</h3>
                <p>
                  Our graphic design services extend to creating marketing
                  collateral such as brochures, flyers, and posters. We combine
                  compelling visuals with persuasive copywriting to create
                  eye-catching marketing materials that drive engagement and
                  conversions.
                </p>
              </div>
              <div className="col-lg-6">
                <h3>Web Design</h3>
                <p>
                  We offer web design services to create visually appealing and
                  user-friendly websites that align with your brand identity.
                  Our designs are optimized for usability and conversion,
                  ensuring that your website not only looks great but also
                  achieves your business objectives.
                </p>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-6">
                <h3>Print Design</h3>
                <p>
                  In addition to digital design, we also specialize in print
                  design for various marketing materials such as business cards,
                  stationery, and packaging. Our print designs are meticulously
                  crafted to maintain visual consistency and professionalism.
                </p>
              </div>
              <div className="col-lg-6">
                <h3>Illustrations</h3>
                <p>
                  Our graphic designers are skilled in creating custom
                  illustrations that add a unique and personal touch to your
                  branding and marketing materials. Whether it's hand-drawn
                  illustrations or digital artwork, we can bring your ideas to
                  life.
                </p>
              </div>
            </div>
            {/* FAQ Section */}
            <section id="faq" className="faq mt-5">
              <div className="container">
                <div className="row text-center">
                  <h1 className="display-3 fw-bold text-uppercase">FAQs</h1>
                  <div className="heading-line"></div>
                  <p className="lead">
                    Frequently Asked Questions about Graphic Design and Adateck
                  </p>
                </div>
                <div className="row mt-5">
                  <div className="col-md-12">
                    <div className="accordion" id="accordionExample">
                      {/* FAQ 1 */}
                      <div className="accordion-item shadow mb-3">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            What graphic design services does Adateck offer?
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            Adateck offers a wide range of graphic design
                            services including logo design, brand identity
                            development, marketing collateral design, web
                            design, print design, and illustrations. We have a
                            team of talented designers who can bring your ideas
                            to life with creative and professional designs.
                          </div>
                        </div>
                      </div>
                      {/* FAQ 2 */}
                      <div className="accordion-item shadow mb-3">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            How can graphic design benefit my business?
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            Graphic design plays a crucial role in branding,
                            marketing, and communication. Professionally
                            designed graphics help establish brand identity,
                            attract attention, and convey messages effectively.
                            Whether it's through logos, advertisements, or
                            website designs, good graphic design can enhance
                            your business's image and credibility.
                          </div>
                        </div>
                      </div>
                      {/* FAQ 3 */}
                      <div className="accordion-item shadow mb-3">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            How do you ensure the quality of graphic design
                            services at Adateck?
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            At Adateck, we have a rigorous quality assurance
                            process in place to ensure that our graphic design
                            services meet the highest standards. Our designers
                            undergo regular training and workshops to stay
                            updated with the latest design trends and
                            techniques. We also conduct thorough reviews and
                            revisions to refine designs until they meet our
                            clients' expectations.
                          </div>
                        </div>
                      </div>
                      {/* FAQ 4 */}
                      <div className="accordion-item shadow mb-3">
                        <h2 className="accordion-header" id="headingFour">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            Can Adateck handle large-scale graphic design
                            projects?
                          </button>
                        </h2>
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFour"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            Yes, Adateck has experience handling large-scale
                            graphic design projects for businesses of all sizes.
                            Whether you need a single logo design or a complete
                            brand identity overhaul, we have the resources and
                            expertise to deliver high-quality designs within
                            stipulated timelines.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </>
  );
};

export default GraphicsDesign;
