import { Link } from "react-router-dom";
import "./ourPortfolio.scss";
import { Helmet } from "react-helmet";

const OurPortfolio = () => {
  const webs = [
    {
      imageSrc: "/images/cvvlogs.jpeg",
      title: "Cvvlogs",
      description:
        "Health Sector Job Portal Our project is an innovative web platform and mobile application designed to revolutionize the job search process in the health sector.",
      url: "/cvvlogs",
    },
    {
      imageSrc: "/images/previsit-review.jpeg",
      title: "Previsit Review",
      description:
        "Streamlined Healthcare Management Our project is a web platform designed to enhance the patient experience and streamline the healthcare process.",
      url: "/previsitreview",
    },
    {
      imageSrc: "/images/express-triage.jpeg",
      title: "Express Triage",
      description:
        "Welcome to Express Triage, your trusted ally in contemporary healthcare. Discover our mission, values, and the dedicated team driving our groundbreaking platform.",
      url: "/express-triage",
    },

    {
      imageSrc: "/images/miner-lab.jpg",
      title: "Qubic Lab",
      description:
        "Large-scale miners often seek collaborative solutions to optimize their operations and enhance profitability in cryptocurrency mining. ",
      url: "/qubic-lab",
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          Our Portfolio | Expert App Development Solutions | ADATECK{" "}
        </title>
        <meta
          name="description"
          content="Learn more about ADATECK, a leader in backend and frontend app development. Our dedicated team of professionals is committed to delivering cutting-edge, customized solutions for your business. Explore our mission, values, and the expertise that sets us apart in the tech industry. Contact us to elevate your digital presence with our innovative app development services."
        />
      </Helmet>
      <div style={{ marginTop: "140px", marginBottom: "100px" }}>
        <div className="row text-center mb-4">
          <h1 className="display-3 fw-bold text-uppercase">Our Portfolio</h1>
          <div className="heading-line"></div>
        </div>
        <div className="container-lg">
          <div className="row">
            {webs?.map((blog) => (
              <div key={blog.title} className="col-lg-4 col-md-6 col-sm-12">
                <Link
                  className="card mb-3"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                  to={blog.url}
                  style={{ height: "450px" }}
                >
                  <img
                    src={blog.imageSrc}
                    className="card-img-top"
                    alt={blog.title}
                  />
                  <div className="card-body">
                    <h5 className="card-title fw-bold">{blog.title}</h5>
                    <p className="card-text">{blog.description}</p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default OurPortfolio;
