import React from "react";
import { Helmet } from "react-helmet";

const DigitalMarketing = () => {
  return (
    <>
      <Helmet>
        <title>Expert Digital Marketing Services | ADATECK </title>
        <meta
          name="description"
          content="Enhance your online visibility with ADATECK's expert digital marketing services. Our comprehensive solutions include SEO, social media marketing, PPC, and content marketing to drive traffic and boost conversions. Tailored to your business needs, our strategies ensure maximum ROI and growth. Contact us today to elevate your digital presence with our innovative marketing expertise."
        />
      </Helmet>
      <div style={{ marginTop: "110px", marginBottom: "50px" }}>
        <section className="py-5">
          <div className="container">
            <div className="row text-center">
              <h1 className="display-3 fw-bold text-capitalize">
                Digital Marketing
              </h1>
              <div className="heading-line"></div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <h3>Search Engine Optimization (SEO)</h3>
                <p>
                  We optimize your website to rank higher in search engine
                  results pages (SERPs) and drive organic traffic. Our SEO
                  strategies focus on improving website visibility, keyword
                  targeting, content optimization, and backlink building.
                </p>
              </div>
              <div className="col-lg-6">
                <h3>Pay-Per-Click (PPC) Advertising</h3>
                <p>
                  We create and manage targeted PPC campaigns to attract
                  qualified leads and maximize ROI. Our PPC experts handle
                  keyword research, ad creation, bid management, and performance
                  tracking to ensure optimal campaign results.
                </p>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-6">
                <h3>Social Media Marketing (SMM)</h3>
                <p>
                  We develop and execute social media strategies to engage
                  audiences, build brand awareness, and drive website traffic.
                  Our SMM services include content creation, community
                  management, paid advertising, and performance analysis across
                  major social media platforms.
                </p>
              </div>
              <div className="col-lg-6">
                <h3>Content Marketing</h3>
                <p>
                  We produce high-quality content to attract, educate, and
                  convert your target audience. Our content marketing services
                  include blog posts, articles, infographics, videos, and
                  whitepapers tailored to your brand voice and audience
                  interests.
                </p>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-6">
                <h3>Email Marketing</h3>
                <p>
                  We design and execute email campaigns to nurture leads,
                  promote products/services, and drive conversions. Our email
                  marketing strategies focus on segmentation, personalization,
                  automation, and analytics to deliver targeted and measurable
                  results.
                </p>
              </div>
              <div className="col-lg-6">
                <h3>Analytics and Reporting</h3>
                <p>
                  We provide detailed analytics and performance reports to track
                  the effectiveness of your digital marketing efforts. Our
                  analytics tools and metrics help you understand audience
                  behavior, campaign performance, and ROI to make data-driven
                  decisions and optimize future strategies.
                </p>
              </div>
            </div>
            {/* FAQ Section */}
            <section id="faq" className="faq mt-5">
              <div className="container">
                <div className="row text-center">
                  <h1 className="display-3 fw-bold text-uppercase">FAQs</h1>
                  <div className="heading-line"></div>
                  <p className="lead">
                    Frequently Asked Questions about Digital Marketing and
                    Adateck
                  </p>
                </div>
                <div className="row mt-5">
                  <div className="col-md-12">
                    <div className="accordion" id="accordionExample">
                      {/* FAQ 1 */}
                      <div className="accordion-item shadow mb-3">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            What are the benefits of SEO for my business?
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            SEO improves your website's visibility, drives
                            organic traffic, enhances brand credibility, and
                            increases conversions. It helps you reach your
                            target audience, outrank competitors, and achieve
                            long-term growth and success online.
                          </div>
                        </div>
                      </div>
                      {/* FAQ 2 */}
                      <div className="accordion-item shadow mb-3">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            How can social media marketing benefit my business?
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            Social media marketing increases brand awareness,
                            builds customer relationships, drives website
                            traffic, and generates leads. It allows you to
                            engage with your audience, showcase your
                            products/services, and capitalize on user-generated
                            content to grow your business and foster brand
                            loyalty.
                          </div>
                        </div>
                      </div>
                      {/* FAQ 3 */}
                      <div className="accordion-item shadow mb-3">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            What is the importance of email marketing?
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            Email marketing is essential for nurturing leads,
                            promoting products/services, and retaining
                            customers. It allows you to deliver targeted
                            messages directly to subscribers' inboxes, drive
                            conversions, and maintain regular communication to
                            build trust and brand loyalty over time.
                          </div>
                        </div>
                      </div>
                      {/* FAQ 4 */}
                      <div className="accordion-item shadow mb-3">
                        <h2 className="accordion-header" id="headingFour">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            How can I measure the success of my digital
                            marketing campaigns?
                          </button>
                        </h2>
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFour"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            You can measure campaign success through various
                            metrics such as website traffic, conversion rate,
                            click-through rate (CTR), engagement, social media
                            metrics, email open and click rates, and return on
                            investment (ROI). Analyzing these metrics helps you
                            understand campaign performance, identify areas for
                            improvement, and optimize your strategies for better
                            results.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </>
  );
};

export default DigitalMarketing;
