import React from "react";
import "./blogs.scss";
import { Link } from "react-router-dom";

const Blogs = () => {
  document.title = "Blogs";

  return (
    <div style={{ marginTop: "140px" }}>
      <section id="blogs" className="blogs">
        <div className="container">
          <div className="row text-center ">
            <h1 className="display-3 fw-bold text-uppercase">Blogs</h1>
            <div className="heading-line"></div>
            <p className="lead">
              A learning hub to provide you with extensive knowledge
            </p>
          </div>

          {/* Cards Section */}
          <div className="row">
            {[
              {
                imageSrc:
                  "https://www.shutterstock.com/image-photo/bloggingblog-concepts-ideas-white-worktable-600nw-1029506242.jpg",
                title: "Web Development in Modern Times",
                description:
                  "Web development has evolved significantly over the years, especially in modern times where technologies and frameworks are constantly changing and improving.",
                url: "/blog",
              },
            ].map((blog) => (
              <div key={blog.title} className="col-lg-4 col-md-6 col-sm-12">
                <Link
                  className="card mb-3"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                  to={blog.url}
                >
                  <img
                    src={blog.imageSrc}
                    className="card-img-top"
                    alt={blog.title}
                  />
                  <div className="card-body">
                    <h5 className="card-title fw-bold">{blog.title}</h5>
                    <p className="card-text">{blog.description}</p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blogs;
