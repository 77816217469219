const Faq = () => {
  document.title = "Faq";

  return (
    <div style={{ marginTop: "140px" }}>
      <section id="faq" className="faq">
        <div className="container">
          <div className="row text-center">
            <h1 className="display-3 fw-bold text-uppercase">faq</h1>
            <div className="heading-line"></div>
            <p className="lead">
              frequently asked questions, get knowledge befere hand
            </p>
          </div>
          {/* <!-- ACCORDION CONTENT  --> */}
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="accordion" id="accordionExample">
                {/* <!-- ACCORDION ITEM 1 --> */}
                <div className="accordion-item shadow mb-3">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      What services does your IT and software company offer?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      We offer a wide range of services including IT
                      infrastructure setup and maintenance, custom software
                      development, graphic design, web development, mobile app
                      development, and digital marketing solutions. We strive to
                      be your one-stop destination for all your technology
                      needs.
                    </div>
                  </div>
                </div>
                {/* <!-- ACCORDION ITEM 2 --> */}
                <div className="accordion-item shadow mb-3">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      How long does it typically take to develop a custom
                      software solution?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      The development timeline depends on the complexity and
                      scope of the project. We work closely with our clients to
                      understand their requirements and provide an estimated
                      timeline. On average, it can take several weeks to several
                      months to complete a custom software development project.
                    </div>
                  </div>
                </div>
                {/* <!-- ACCORDION ITEM 3 --> */}
                <div className="accordion-item shadow mb-3">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Can you help with graphic design for branding purposes?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Absolutely! Our graphic design services cover various
                      aspects of branding, including logo design, marketing
                      collateral, brand identity development, and visual assets
                      creation. We work closely with clients to understand their
                      brand vision and deliver designs that align with their
                      brand identity and goals.
                    </div>
                  </div>
                </div>
                {/* <!-- ACCORDION ITEM 4 --> */}
                <div className="accordion-item shadow mb-3">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      What is the importance of responsive web design?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Responsive web design is crucial in today's mobile-driven
                      world. It ensures that your website adapts and displays
                      properly on different devices and screen sizes, providing
                      a seamless user experience. A responsive website not only
                      improves user engagement but also helps with search engine
                      optimization and overall online visibility..
                    </div>
                  </div>
                </div>
                <div className="accordion-item shadow mb-3">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      How can digital marketing benefit my business?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Digital marketing plays a vital role in expanding your
                      online presence, reaching a wider audience, and driving
                      targeted traffic to your website. It encompasses various
                      strategies like search engine optimization (SEO), social
                      media marketing, pay-per-click (PPC) advertising, and
                      content marketing. Effective digital marketing can boost
                      brand awareness, generate leads, and ultimately increase
                      conversions and business growth.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Faq;
