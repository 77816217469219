import React from "react";
import { Helmet } from "react-helmet";

const WebDevelopment = () => {
  return (
    <>
      {" "}
      <Helmet>
        <title>Professional Web Development Services | ADATECK </title>
        <meta
          name="description"
          content="Unlock your business potential with ADATECK's professional web development services. Our skilled developers create responsive, user-friendly, and high-performance websites tailored to your needs. From e-commerce to custom web applications, we deliver innovative solutions to enhance your online presence. Contact us today to start your web development project and achieve your digital goals."
        />
      </Helmet>
      <div style={{ marginTop: "110px", marginBottom: "50px" }}>
        <section className="py-5">
          <div className="container">
            <div className="row text-center">
              <h1 className="display-3 fw-bold text-capitalize">
                Web Development
              </h1>
              <div className="heading-line"></div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <h3>Frontend Development</h3>
                <p>
                  Our frontend development team specializes in creating
                  responsive and user-friendly interfaces using the latest web
                  technologies. We leverage frameworks like React, Angular, and
                  Vue.js to build dynamic and interactive user interfaces.
                </p>
              </div>
              <div className="col-lg-6">
                <h3>Backend Development</h3>
                <p>
                  Our backend developers are experts in building robust and
                  scalable server-side applications. We use technologies like
                  Node.js and Express.js to develop APIs and handle data storage
                  and retrieval efficiently.
                </p>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-6">
                <h3>Full Stack Development</h3>
                <p>
                  Our full stack developers have proficiency in both frontend
                  and backend technologies, allowing them to develop end-to-end
                  solutions. They are adept at integrating frontend and backend
                  components to create seamless and feature-rich web
                  applications.
                </p>
              </div>
              <div className="col-lg-6">
                <h3>Responsive Design</h3>
                <p>
                  We prioritize responsive design to ensure that our websites
                  and web applications look and perform optimally across all
                  devices. Our designs adapt fluidly to different screen sizes
                  and resolutions, providing an exceptional user experience on
                  desktops, tablets, and smartphones.
                </p>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-6">
                <h3>Mobile App Development</h3>
                <p>
                  In addition to web development, we also specialize in mobile
                  app development. Our team of experienced developers builds
                  native and cross-platform mobile apps using technologies like
                  React Native, Flutter, and Xamarin, ensuring high performance
                  and a seamless user experience.
                </p>
              </div>
              <div className="col-lg-6">
                <h3>Progressive Web Apps (PWAs)</h3>
                <p>
                  We develop Progressive Web Apps (PWAs) that combine the best
                  features of web and mobile applications. PWAs are fast,
                  reliable, and engaging, providing users with a native app-like
                  experience on the web. With offline support, push
                  notifications, and installation prompts, PWAs offer enhanced
                  functionality and accessibility.
                </p>
              </div>
            </div>
            {/* FAQ Section */}
            <section id="faq" className="faq mt-5">
              <div className="container">
                <div className="row text-center">
                  <h1 className="display-3 fw-bold text-uppercase">FAQs</h1>
                  <div className="heading-line"></div>
                  <p className="lead">
                    Frequently Asked Questions about Web Development and Adateck
                  </p>
                </div>
                <div className="row mt-5">
                  <div className="col-md-12">
                    <div className="accordion" id="accordionExample">
                      {/* FAQ 1 */}
                      <div className="accordion-item shadow mb-3">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            What services does Adateck offer?
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            Adateck offers a comprehensive range of IT services
                            including web development, mobile app development,
                            custom software solutions, digital marketing,
                            graphic design, and IT consulting services. We
                            strive to provide tailored solutions to meet our
                            clients' unique business requirements.
                          </div>
                        </div>
                      </div>
                      {/* FAQ 2 */}
                      <div className="accordion-item shadow mb-3">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            How experienced is Adateck in web development?
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            Adateck boasts a team of skilled and experienced web
                            developers who have worked on a wide range of
                            projects across various industries. Our developers
                            stay updated with the latest technologies and best
                            practices to deliver cutting-edge web solutions that
                            meet our clients' business objectives.
                          </div>
                        </div>
                      </div>
                      {/* FAQ 3 */}
                      <div className="accordion-item shadow mb-3">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            How long does it take to develop a website with
                            Adateck?
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            The timeline for website development depends on the
                            complexity and scope of the project. After
                            understanding your requirements, we provide an
                            estimated timeline for project completion. We strive
                            to deliver projects efficiently without compromising
                            on quality.
                          </div>
                        </div>
                      </div>
                      {/* FAQ 4 */}
                      <div className="accordion-item shadow mb-3">
                        <h2 className="accordion-header" id="headingFour">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            Can Adateck assist with website maintenance and
                            support?
                          </button>
                        </h2>
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFour"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            Yes, Adateck offers website maintenance and support
                            services to ensure that your website remains
                            up-to-date, secure, and optimized for performance.
                            We provide regular updates, security patches, and
                            technical support to address any issues that may
                            arise.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </>
  );
};

export default WebDevelopment;
