import { Fragment } from "react";
import "./footer.scss";
import Logo from "../../static/logo-png.png";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  return (
    <Fragment>
      <footer class="footer">
        <div class="container">
          <div class="row justify-content-between">
            {/* <!-- CONTENT FOR THE MOBILE NUMBER  --> */}
            <div class="col-md-4 col-lg-4 contact-box pt-1 d-md-block d-lg-flex d-flex justify-content-center">
              <div class="contact-box__icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-phone-call"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                  <path d="M15 7a2 2 0 0 1 2 2" />
                  <path d="M15 3a6 6 0 0 1 6 6" />
                </svg>
              </div>
              <div class="contact-box__info">
                <a href="tel:+13462966212" class="contact-box__info--title">
                  +1 346-296-6212
                </a>
                <p class="contact-box__info--subtitle"> Mon-Fri 9am-6pm</p>
              </div>
            </div>
            {/* <!-- CONTENT FOR EMAIL  --> */}
            <div class="col-md-4 col-lg-4 contact-box pt-1 d-md-block d-lg-flex d-flex justify-content-center">
              <div class="contact-box__icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-mail-opened"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <polyline points="3 9 12 15 21 9 12 3 3 9" />
                  <path d="M21 9v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10" />
                  <line x1="3" y1="19" x2="9" y2="13" />
                  <line x1="15" y1="13" x2="21" y2="19" />
                </svg>
              </div>
              <div class="contact-box__info">
                <a
                  href="mailto:info@adateck.com"
                  class="contact-box__info--title"
                >
                  info@adateck.com
                </a>
                <p class="contact-box__info--subtitle">Online support</p>
              </div>
            </div>
            {/* <!-- CONTENT FOR LOCATION  --> */}
            <div class="col-md-4 col-lg-4 contact-box pt-1 d-md-block d-lg-flex d-flex justify-content-center">
              <div class="contact-box__icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-map-2"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <line x1="18" y1="6" x2="18" y2="6.01" />
                  <path d="M18 13l-3.5 -5a4 4 0 1 1 7 0l-3.5 5" />
                  <polyline points="10.5 4.75 9 4 3 7 3 20 9 17 15 20 21 17 21 15" />
                  <line x1="9" y1="4" x2="9" y2="17" />
                  <line x1="15" y1="15" x2="15" y2="20" />
                </svg>
              </div>
              <div class="contact-box__info">
                <a href="#" class="contact-box__info--title">
                  Texas, USA
                </a>
                <p class="contact-box__info--subtitle">TX 77478, US</p>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- START THE SOCIAL MEDIA CONTENT  --> */}
        <div class="footer-sm" style={{ backgroundColor: "#212121" }}>
          <div class="container">
            <div class="row py-4 text-white justify-content-between ">
              <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                <h3>Connect with us on social media </h3>
                <a href="#">
                  <i class="fab fa-facebook"></i>
                </a>
                <a href="#">
                  <i class="fab fa-twitter"></i>
                </a>
                {/* <a href="#">
                  <i class="fab fa-github"></i>
                </a> */}
                <a
                  href="https://www.linkedin.com/company/adateck/?viewAsMember=true"
                  target="_blank"
                >
                  <i class="fab fa-linkedin"></i>
                </a>
                <a href="#">
                  <i class="fab fa-instagram"></i>
                </a>
              </div>
              <div class="col-lg-3 col-md-6 mb-4 mb-md-0 footer-links">
                <h3>Useful Links</h3>
                <ul>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <Link to="../">Home</Link>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <Link to="/about-us">About us</Link>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <Link to="/faq">Faq</Link>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <Link to="/careers">Careers</Link>
                  </li>
                </ul>
              </div>
              <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                <h3>Contact Us </h3>
                <div>
                  <p>Name: ADATECK, LLC</p>
                  <p>
                    <i class="fa-solid fa-location-dot"></i> 12808 W AIRPORT
                    BLVD <br /> STE 270J, SUGAR LAND, TX 77478 <br />
                    <span
                      onClick={() =>
                        (window.location.href = "mailto:info@adateck.com")
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      <i className="fa-regular fa-envelope"></i>{" "}
                      info@adateck.com
                    </span>
                  </p>
                  {/* <p>+999 90932 627</p> */}
                </div>
              </div>
              <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                <h3>Disclaimer </h3>
                <div>
                  At Adateck, we are committed to providing exceptional digital
                  solutions. We proudly accept Stripe payments to ensure a
                  secure and convenient transaction experience for our valued
                  clients. Our dedication to your satisfaction is paramount, and
                  we guarantee a full refund if our services ever fall short of
                  your expectations. Your trust in our expertise is our
                  priority, and we look forward to exceeding your digital needs.
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- START THE CONTENT FOR THE CAMPANY INFO --> */}
        {/* <div class="container mt-5">
    <div class="row text-white justify-content-center mt-3 pb-3">
      <div class="col-12 col-sm-6 col-lg-6 mx-auto">
        <h5 class="text-capitalize fw-bold">Campany name</h5>
        <hr class="bg-white d-inline-block mb-4" style="width: 60px; height: 2px;">
        <p class="lh-lg">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem ex obcaecati blanditiis reprehenderit ab mollitia voluptatem consectetur?
        </p>
      </div>
      <div class="col-12 col-sm-6 col-lg-2 mb-4 mx-auto">
        <h5 class="text-capitalize fw-bold">Products</h5>
        <hr class="bg-white d-inline-block mb-4" style="width: 60px; height: 2px;">
        <ul class="list-inline campany-list">
          <li><a href="#">Lorem ipsum</a></li>
          <li><a href="#">Lorem ipsum</a></li>
          <li><a href="#">Lorem ipsum</a></li>
          <li><a href="#">Lorem ipsum</a></li>
        </ul>
      </div>
      <div class="col-12 col-sm-6 col-lg-2 mb-4 mx-auto">
        <h5 class="text-capitalize fw-bold">useful links</h5>
        <hr class="bg-white d-inline-block mb-4" style="width: 60px; height: 2px;">
        <ul class="list-inline campany-list">
          <li><a href="#"> Your Account</a></li>
          <li><a href="#">Become an Affiliate</a></li>
          <li><a href="#">create an account</a></li>
          <li><a href="#">Help</a></li>
        </ul>
      </div>
      <div class="col-12 col-sm-6 col-lg-2 mb-4 mx-auto">
        <h5 class="text-capitalize fw-bold">contact</h5>
        <hr class="bg-white d-inline-block mb-4" style="width: 60px; height: 2px;">
        <ul class="list-inline campany-list">
          <li><a href="#">Lorem ipsum</a></li>
          <li><a href="#">Lorem ipsum</a></li>
          <li><a href="#">Lorem ipsum</a></li>
          <li><a href="#">Lorem ipsum</a></li>
        </ul>
      </div>
    </div>
  </div> */}

        {/* <!-- START THE COPYRIGHT INFO  --> */}
        <div class="footer-bottom pt-5 pb-5">
          <div class="container">
            <div class="row text-center text-white">
              <div class="col-12">
                <div class="footer-bottom__copyright">
                  © Copyright 2023 <a href="#">Adateck</a>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* <!-- BACK TO TOP BUTTON  --> */}
      <a href="#" class="shadow btn-primary rounded-circle back-to-top">
        <i class="fas fa-chevron-up"></i>
      </a>
    </Fragment>
  );
};

export default Footer;
