import "./contact-form.scss";
import { toast } from "react-toastify";
import { useRef, useState } from "react";
import emailjs, { send } from "@emailjs/browser";

const ContactForm = () => {
  const form = useRef();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    console.log(form.current);

    // emailjs
    //   .sendForm(
    //     process.env.REACT_APP_SERVICE_ID,
    //     process.env.REACT_APP_TEMPLATE_ID,
    //     form.current,
    //     process.env.REACT_APP_PUBLIC_KEY
    //   )
    //   .then(
    //     (result) => {
    //       if (result?.text === "OK") {
    //         toast.success("Email Sent Successfully!", {
    //           position: toast.POSITION.TOP_RIGHT,
    //         });
    //         setIsSubmitting(false);

    //         form.current.reset();
    //       }
    //     },
    //     (error) => {
    //       toast.error(error.text, {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //       setIsSubmitting(false);
    //     }
    //   );
    toast.success("Email Sent Successfully!", {
      position: toast.POSITION.TOP_RIGHT,
    });
    form.current.reset();
    setIsSubmitting(false);
  };
  return (
    <section id="contact" className="get-started">
      <div className="container">
        <div className="row text-center">
          <h1 className="display-3 fw-bold text-capitalize">Get started</h1>
          <div className="heading-line"></div>
        </div>

        {/* <!-- START THE CTA CONTENT  --> */}
        <div className="row text-white">
          <div
            className="col-12 col-lg-6"
            style={{ backgroundColor: "#B624E9" }}
          >
            <div className="cta-info w-100">
              <h4 className="display-4 fw-bold">
                100% Satisfaction Guaranteed
              </h4>
              <p className="lh-lg">
                IT Solutions with Experienced Staff of Engineers provide you
                with optimal IT experience!
              </p>
              <h3 className="display-3--brief" style={{ color: "white" }}>
                What will be the next step?
              </h3>
              <ul className="cta-info__list">
                <li>We'll prepare the proposal.</li>
                <li>we'll discuss it together.</li>
                <li>let's start the discussion.</li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-lg-6" style={{ backgroundColor: "white" }}>
            <div className="form w-100 pb-2">
              <h4 className="display-3--title mb-5">start your project</h4>
              <form ref={form} onSubmit={sendEmail} className="row">
                <div className="col-lg-6 col-md mb-3">
                  <input
                    type="text"
                    placeholder="First Name"
                    id="inputFirstName"
                    className="shadow form-control form-control-lg"
                    required
                  />
                </div>
                <div className="col-lg-6 col-md mb-3">
                  <input
                    type="text"
                    placeholder="Last Name"
                    id="inputLastName"
                    className="shadow form-control form-control-lg"
                    required
                  />
                </div>
                <div className="col-lg-12 mb-3">
                  <input
                    type="Email"
                    placeholder="Email Address"
                    id="inputEmail"
                    className="shadow form-control form-control-lg"
                    required
                  />
                </div>
                <div className="col-lg-12 mb-3">
                  <textarea
                    name="Message"
                    placeholder="Message"
                    id="message"
                    rows="8"
                    className="shadow form-control form-control-lg"
                    required
                  ></textarea>
                </div>
                <div className="text-center d-grid mt-1">
                  {/* <button
                    type="button"
                    className="btn btn-primary rounded-pill pt-3 pb-3"
                    style={{
                      backgroundColor: "#9926f0",
                    }}
                    onClick={sendEmail}
                  >
                    submit
                    <i className="fas fa-paper-plane"></i>
                  </button> */}
                  <button
                    type="submit"
                    className="btn btn-primary rounded-pill pt-3 pb-3"
                    disabled={isSubmitting}
                    style={{
                      backgroundColor: "#9926f0",
                    }}
                  >
                    {isSubmitting ? (
                      <div style={{ padding: "0 15px" }}>
                        <div
                          class="spinner-border spinner-border-sm"
                          role="status"
                        ></div>
                      </div>
                    ) : (
                      <>
                        {" "}
                        Submit <i className="fas fa-paper-plane"></i>
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ContactForm;
