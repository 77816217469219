import React from "react";
import { Helmet } from "react-helmet";

const MobileAppDevelopment = () => {
  return (
    <>
      <Helmet>
        <title>Expert Mobile App Development Services | ADATECK </title>
        <meta
          name="description"
          content="Transform your business with ADATECK's professional mobile app development services. Our experienced developers create custom, high-performance mobile apps for iOS and Android that deliver exceptional user experiences. From concept to launch, we provide innovative solutions tailored to your needs. Contact us today to bring your mobile app vision to life with our top-notch development expertise."
        />
      </Helmet>
      <div style={{ marginTop: "110px", marginBottom: "50px" }}>
        <section className="py-5">
          <div className="container">
            <div className="row text-center">
              <h1 className="display-3 fw-bold text-capitalize">
                Mobile App Development
              </h1>
              <div className="heading-line"></div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <h3>Native App Development</h3>
                <p>
                  Our mobile app development team specializes in building native
                  applications for iOS and Android platforms. We leverage
                  technologies like Swift and Kotlin to create high-performance
                  and feature-rich mobile apps that offer seamless user
                  experiences.
                </p>
              </div>
              <div className="col-lg-6">
                <h3>Cross-Platform App Development</h3>
                <p>
                  In addition to native app development, we also excel in
                  cross-platform app development using frameworks like React
                  Native, Flutter, and Xamarin. By developing cross-platform
                  apps, we ensure compatibility across multiple platforms while
                  reducing development time and costs.
                </p>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-6">
                <h3>UI/UX Design for Mobile Apps</h3>
                <p>
                  We prioritize user interface (UI) and user experience (UX)
                  design to create engaging and intuitive mobile app interfaces.
                  Our designers focus on designing mobile-first interfaces that
                  are optimized for small screens and touch interactions,
                  resulting in seamless and delightful user experiences.
                </p>
              </div>
              <div className="col-lg-6">
                <h3>Mobile App Testing and QA</h3>
                <p>
                  We conduct rigorous testing and quality assurance (QA) to
                  ensure the reliability, performance, and security of our
                  mobile apps. Our QA engineers employ manual and automated
                  testing techniques to identify and resolve any bugs or issues
                  before app deployment.
                </p>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-6">
                <h3>Deployment and Maintenance</h3>
                <p>
                  We assist clients in deploying their mobile apps to app stores
                  like the Apple App Store and Google Play Store. Additionally,
                  we offer ongoing maintenance and support services to ensure
                  that mobile apps remain up-to-date, secure, and optimized for
                  performance.
                </p>
              </div>
              <div className="col-lg-6">
                <h3>Integration with Backend Systems</h3>
                <p>
                  Our mobile app developers are proficient in integrating mobile
                  apps with backend systems and APIs to enable seamless data
                  exchange and functionality. Whether it's integrating with
                  existing systems or developing custom APIs, we ensure
                  interoperability and scalability for mobile apps.
                </p>
              </div>
            </div>
            {/* FAQ Section */}
            <section id="faq" className="faq mt-5">
              <div className="container">
                <div className="row text-center">
                  <h1 className="display-3 fw-bold text-uppercase">FAQs</h1>
                  <div className="heading-line"></div>
                  <p className="lead">
                    Frequently Asked Questions about Mobile App Development and
                    Adateck
                  </p>
                </div>
                <div className="row mt-5">
                  <div className="col-md-12">
                    <div className="accordion" id="accordionExample">
                      {/* FAQ 1 */}
                      <div className="accordion-item shadow mb-3">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            What platforms does Adateck develop mobile apps for?
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            Adateck develops mobile apps for both iOS and
                            Android platforms. We specialize in native app
                            development for each platform as well as
                            cross-platform development using frameworks like
                            React Native, Flutter, and Xamarin.
                          </div>
                        </div>
                      </div>
                      {/* FAQ 2 */}
                      <div className="accordion-item shadow mb-3">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            How long does it take to develop a mobile app with
                            Adateck?
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            The timeline for mobile app development varies based
                            on the complexity and scope of the project. After
                            understanding your requirements, we provide an
                            estimated timeline for project completion. We strive
                            to deliver high-quality mobile apps efficiently
                            without compromising on functionality or user
                            experience.
                          </div>
                        </div>
                      </div>
                      {/* FAQ 3 */}
                      <div className="accordion-item shadow mb-3">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            Can Adateck assist with app store deployment?
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            Yes, Adateck provides assistance with app store
                            deployment for both iOS and Android platforms. We
                            guide clients through the submission process and
                            ensure that their mobile apps meet all app store
                            guidelines and requirements for successful
                            publication.
                          </div>
                        </div>
                      </div>
                      {/* FAQ 4 */}
                      <div className="accordion-item shadow mb-3">
                        <h2 className="accordion-header" id="headingFour">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            Does Adateck offer ongoing maintenance and support
                            for mobile apps?
                          </button>
                        </h2>
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFour"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            Yes, Adateck provides ongoing maintenance and
                            support services for mobile apps to ensure that they
                            remain up-to-date, secure, and optimized for
                            performance. We offer regular updates, security
                            patches, and technical support to address any issues
                            that may arise post-launch.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>{" "}
    </>
  );
};

export default MobileAppDevelopment;
