import React from "react";
import "./blog.scss";
import { Link } from "react-router-dom";

const Blog = () => {
  document.title = "Blog";

  return (
    <div style={{ marginTop: "140px" }}>
      <section id="blogs" className="blogs">
        <div className="container">
          <div className="row text-center">
            <h1 className="display-3 fw-bold text-uppercase">
              Web Development in Modern Times
            </h1>
            <div className="heading-line"></div>
          </div>

          <img
            src="https://www.shutterstock.com/image-photo/bloggingblog-concepts-ideas-white-worktable-600nw-1029506242.jpg"
            className="card-img-top"
            alt={`Blog image`}
          />

          {/* Blog Content */}
          <div className="row mt-5">
            <div className="col">
              <p className="blog-description">
                Web development has evolved significantly over the years,
                especially in modern times where technologies and frameworks are
                constantly changing and improving. In this blog post, we will
                explore some of the key aspects of web development in today's
                world.
              </p>

              <h3 className="blog-heading">Responsive Web Design</h3>
              <p className="blog-description">
                With the widespread use of mobile devices, responsive web design
                has become essential. Websites need to adapt to various screen
                sizes and resolutions to provide a seamless experience across
                different devices. Developers now focus on building responsive
                and mobile-friendly websites using CSS frameworks like Bootstrap
                and Flexbox.
              </p>

              <h3 className="blog-heading">JavaScript Frameworks</h3>
              <p className="blog-description">
                JavaScript frameworks such as React, Angular, and Vue.js have
                revolutionized web development by allowing developers to build
                dynamic and interactive user interfaces. These frameworks
                facilitate the development of single-page applications (SPAs)
                and offer features like virtual DOM, component-based
                architecture, and state management.
              </p>

              <h3 className="blog-heading">API Integration</h3>
              <p className="blog-description">
                Modern web applications often rely on external APIs to fetch
                data and perform various tasks. Whether it's integrating social
                media APIs for authentication or consuming RESTful APIs for data
                manipulation, developers need to understand how to work with
                APIs efficiently. Tools like Axios and Fetch API simplify the
                process of making HTTP requests and handling responses.
              </p>

              <h3 className="blog-heading">Progressive Web Apps (PWAs)</h3>
              <p className="blog-description">
                Progressive Web Apps combine the best of web and mobile
                applications to deliver a native app-like experience on the web.
                PWAs are fast, reliable, and engaging, thanks to features like
                offline support, push notifications, and installation prompts.
                Developers can build PWAs using technologies like Service
                Workers, Web App Manifests, and IndexedDB.
              </p>

              <h3 className="blog-heading">Serverless Architecture</h3>
              <p className="blog-description">
                Serverless architecture allows developers to build and deploy
                applications without managing servers. Platforms like AWS Lambda
                and Google Cloud Functions enable developers to focus on writing
                code without worrying about infrastructure management.
                Serverless functions are event-driven and scale automatically,
                making them ideal for modern web applications.
              </p>

              <h3 className="blog-heading">Conclusion</h3>
              <p className="blog-description">
                In conclusion, web development in modern times is characterized
                by responsiveness, interactivity, and efficiency. Developers
                leverage the latest technologies and best practices to create
                powerful web applications that meet the needs of users in the
                digital age.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blog;
