import "./home.scss";
import { Link } from "react-router-dom";
import secure from "../../static/secure.png";
import mission from "../../static/Mission-text.png";
import ImageOne from "../../static/Images/edit-1.jpg";
// import ImageTwo from "../../static/Images/edit-1.jpg";
import ImageThree from "../../static/Images/edit-2.jpg";
// import ImageFour from "../../static/Images/edit-1.jpg";
import ImageFive from "../../static/Images/edit-3.jpg";
import ImageSix from "../../static/Images/edit-5.jpg";
import ImageSeven from "../../static/Images/edit-4.jpg";
import ContactForm from "../../components/contact-form/contact-form";
import Slider from "react-slick/lib/slider";
import { Helmet } from "react-helmet";

// import ImageEight from "../../static/Images/edit-1.jpg";

const Home = () => {
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
  };

  return (
    <>
      <Helmet>
        <title>
          Expert Backend & Frontend App Development Services | ADATECK
        </title>
        <meta
          name="description"
          content="Discover professional backend and frontend app development services with ADATECK. Our expert team delivers innovative and scalable solutions tailored to your business needs. Boost your digital presence with our top-notch development expertise. Contact us today for custom app solutions."
        />
      </Helmet>
      <section id="home" className="intro-section">
        <div className="container">
          <div className="row align-items-center text-white">
            {/* <!-- START THE CONTENT FOR THE INTRO  --> */}
            <div className="col-md-5 intros text-start">
              <h1 className="display-2">
                <span className="display-2--intro">
                  All The Services You Expect From an IT & Digital Agency
                </span>
                <span className="display-2--description lh-base">
                  Comprehensive IT solutions that encompass software
                  development, ensuring your business stays ahead in the digital
                  era. Our team of experts is dedicated to delivering reliable
                  and innovative technology services, providing you with the
                  competitive edge you seek.
                </span>
              </h1>
              {/* <!-- <button type="button" className="rounded-pill btn-rounded">Get in Touch
          <span><i className="fas fa-arrow-right"></i></span>
        </button> --> */}
            </div>
            {/* <!-- START THE CONTENT FOR THE VIDEO --> */}
            <div className="col-md-7 intros text-end">
              <div className="video-box">
                <img
                  src="images/arts/intro-section-illustration.png"
                  alt="video illutration"
                  className="img-fluid"
                />
                {/* <a
                  href="#"
                  className="glightbox position-absolute top-50 start-50 translate-middle"
                >
                  <span>
                    <i className="fas fa-play-circle"></i>
                  </span>
                  <span className="border-animation border-animation--border-1"></span>
                  <span className="border-animation border-animation--border-2"></span>
                </a> */}
              </div>
            </div>
          </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#ffffff"
            fill-opacity="1"
            d="M0,160L48,176C96,192,192,224,288,208C384,192,480,128,576,133.3C672,139,768,213,864,202.7C960,192,1056,96,1152,74.7C1248,53,1344,107,1392,133.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </section>

      {/* <!-- //////////////////////////////////////////////////////////////////////////////////////////////
                             START SECTION 3 - THE CAMPANIES SECTION  
////////////////////////////////////////////////////////////////////////////////////////////////////--> */}

      {/* <section id="campanies" className="campanies">
        <div className="container">
          <div className="row text-center">
            <h4 className="fw-bold lead mb-3" style={{ color: "black" }}>
              Trusted by companies like
            </h4>
            <div className="heading-line mb-5"></div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-lg-2">
              <div className="campanies__logo-box shadow-sm">
                <img
                  src="images/campanies/campany-1.png"
                  alt="Campany 1 logo"
                  title="Campany 1 Logo"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-4 col-lg-2">
              <div className="campanies__logo-box shadow-sm">
                <img
                  src="images/campanies/campany-2.png"
                  alt="Campany 2 logo"
                  title="Campany 2 Logo"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-4 col-lg-2">
              <div className="campanies__logo-box shadow-sm">
                <img
                  src="images/campanies/campany-3.png"
                  alt="Campany 3 logo"
                  title="Campany 3 Logo"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-4 col-lg-2">
              <div className="campanies__logo-box shadow-sm">
                <img
                  src="images/campanies/campany-4.png"
                  alt="Campany 4 logo"
                  title="Campany 4 Logo"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-4 col-lg-2">
              <div className="campanies__logo-box shadow-sm">
                <img
                  src="images/campanies/campany-5.png"
                  alt="Campany 5 logo"
                  title="Campany 5 Logo"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-4 col-lg-2">
              <div className="campanies__logo-box shadow-sm">
                <img
                  src="images/campanies/campany-6.png"
                  alt="Campany 6 logo"
                  title="Campany 6 Logo"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <!-- //////////////////////////////////////////////////////////////////////////////////////////////
                         START SECTION 4 - THE SERVICES  
///////////////////////////////////////////////////////////////////////////////////////////////////--> */}

      <section id="clients">
        <div className="container">
          <div className="slider-container">
            <Slider {...settings}>
              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="images/companies/1.png"
                  className="img-fluid"
                  alt=""
                />
              </div>

              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="images/companies/2.png"
                  className="img-fluid"
                  alt=""
                />
              </div>

              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="images/companies/3.png"
                  className="img-fluid"
                  alt=""
                />
              </div>

              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="images/companies/4.png"
                  className="img-fluid"
                  alt=""
                />
              </div>

              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="images/companies/5.png"
                  className="img-fluid"
                  alt=""
                />
              </div>

              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="images/companies/6.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="images/companies/7.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="images/companies/8.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="images/companies/9.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="images/companies/10.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="images/companies/11.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="images/companies/12.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="images/companies/13.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="images/companies/14.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="images/companies/15.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="images/companies/16.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="images/companies/17.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="images/companies/18.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="images/companies/19.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="images/companies/20.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="images/companies/21.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </Slider>
          </div>
        </div>
      </section>

      {/* <section id="clients" className="clients section-bg">
        <div className="container">
          <div className="row" data-aos="zoom-in">
            <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img
                src="images/clients/client-1.png"
                className="img-fluid"
                alt=""
              />
            </div>

            <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img
                src="images/clients/client-2.png"
                className="img-fluid"
                alt=""
              />
            </div>

            <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img
                src="images/clients/client-3.png"
                className="img-fluid"
                alt=""
              />
            </div>

            <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img
                src="images/clients/client-4.png"
                className="img-fluid"
                alt=""
              />
            </div>

            <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img
                src="images/clients/client-5.png"
                className="img-fluid"
                alt=""
              />
            </div>

            <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img
                src="images/clients/client-6.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </section> */}

      <section id="services" className="services">
        <div className="container">
          <div className="row text-center">
            <h1 className="display-3 fw-bold">Our Services</h1>
            <div className="heading-line mb-1"></div>
          </div>
          {/* <!-- START THE DESCRIPTION CONTENT  --> */}

          <div className="row pt-2 pb-2 mt-0 mb-3">
            <div className="col-md-6 border-right">
              <div className="bg-white p-3">
                <h2 className="fw-bold text-capitalize text-center">
                  Our Services Range From Initial Design To Deployment Anywhere
                  Anytime
                </h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="bg-white p-4 text-start">
                <p className="fw-light">
                  Adateck is a dynamic software development and digital
                  solutions company at the forefront of technological
                  innovation. With a passion for crafting cutting-edge software
                  and digital strategies, they empower businesses to thrive in
                  the digital age through tailored solutions that drive growth
                  and efficiency.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- START THE CONTENT FOR THE SERVICES  --> */}
        <div className="container">
          {/* <!-- START THE MARKETING CONTENT  --> */}
          <div class="row">
            <div
              class="col-xl-4 col-md-6 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div class="icon-box">
                <div class="icon">
                  <i class="fa-solid fa-images"></i>{" "}
                </div>
                <h4>
                  <Link to="/graphics-design">Graphics Design</Link>
                </h4>
                <p>
                  Our talented graphic designers bring ideas to life, creating
                  visually captivating designs that resonate with your brand
                  identity and engage your target audience across various
                  platforms.
                </p>
              </div>
            </div>

            <div
              class="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div class="icon-box">
                <div class="icon">
                  <i class="fa-brands fa-edge"></i>{" "}
                </div>
                <h4>
                  <Link to="/web-development">Web Development</Link>
                </h4>
                <p>
                  Our team of skilled developers crafts dynamic and
                  user-friendly websites, leveraging the latest technologies to
                  ensure seamless navigation, responsive design, and optimal
                  performance.
                </p>
              </div>
            </div>

            <div
              class="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <div class="icon-box">
                <div class="icon">
                  <i class="fa-solid fa-object-group"></i>{" "}
                </div>
                <h4>
                  <Link to="/ui-ux-development">UI/UX Development</Link>
                </h4>
                <p>
                  We specialize in crafting intuitive and immersive user
                  interfaces, focusing on delivering exceptional user
                  experiences that enhance customer satisfaction and drive
                  conversions.
                </p>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div
              class="col-xl-4 col-md-6 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div class="icon-box">
                <div class="icon">
                  <i class="fa-solid fa-mobile"></i>{" "}
                </div>
                <h4>
                  <Link to="/mobile-app-development">
                    Mobile App Development
                  </Link>
                </h4>
                <p>
                  Our mobile app development team builds innovative and
                  feature-rich applications for iOS and Android platforms,
                  enabling you to reach and engage with your customers on their
                  preferred devices.
                </p>
              </div>
            </div>

            <div
              class="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div class="icon-box">
                <div class="icon">
                  <i class="fa-brands fa-wordpress"></i>{" "}
                </div>
                <h4>
                  <Link to="/wordpress-design">Wordpress Design</Link>
                </h4>
                <p>
                  We offer customized WordPress solutions, combining
                  eye-catching design elements with powerful functionality to
                  create websites that are easy to manage and highly scalable.
                </p>
              </div>
            </div>

            <div
              class="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <div class="icon-box">
                <div class="icon">
                  <i class="fa-solid fa-chart-simple"></i>{" "}
                </div>
                <h4>
                  <Link to="/digital-marketing">Digital Marketing</Link>
                </h4>
                <p>
                  Our digital marketing experts utilize data-driven strategies
                  to boost your online presence, increase brand visibility, and
                  drive targeted traffic to your website, ensuring maximum ROI
                  and business growth.
                </p>
              </div>
            </div>
          </div>{" "}
          <div class="row mt-3">
            <div
              class="col-xl-4 col-md-6 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div class="icon-box">
                <div class="icon">
                  <i class="fa-solid fa-database"></i>{" "}
                </div>
                <h4>
                  <Link to="/data-science">Data Science</Link>
                </h4>
                <p>
                  Our data science team leverages advanced analytics and machine
                  learning to extract actionable insights from complex datasets,
                  driving innovation and informing decision-making for our
                  company's growth.
                </p>
              </div>
            </div>

            <div
              class="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div class="icon-box">
                <div class="icon">
                  <i class="fa-solid fa-user"></i>{" "}
                </div>
                <h4>
                  <Link to="/hire-remote-developer">Hire Remote Developer</Link>
                </h4>
                <p>
                  When you choose to hire a remote developer, you unlock a world
                  of talent and expertise without the limitations of
                  geographical boundaries. Our remote developers are highly
                  skilled professionals who can seamlessly integrate into your
                  team, offering their expertise in software development, web
                  development, and other IT domains. Enjoy the flexibility,
                  cost-effectiveness, and access to a diverse talent pool by
                  hiring remote developers who can work remotely and deliver
                  exceptional results.
                </p>
              </div>
            </div>

            <div
              class="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <div class="icon-box">
                <div class="icon">
                  <i class="fa-solid fa-user-group"></i>{" "}
                </div>
                <h4>
                  <Link to="/hire-remote-team">Hire Remote Team</Link>
                </h4>
                <p>
                  Building a remote team brings numerous benefits to your
                  organization. By assembling a remote team, you gain access to
                  a broader talent pool, including professionals with diverse
                  skill sets and backgrounds. This allows you to scale your team
                  according to project requirements, improve productivity, and
                  reduce costs associated with traditional in-house hiring.
                  Collaborate with our remote teams specialized in IT and
                  software development to drive innovation, achieve project
                  milestones, and meet your business goals effectively.
                </p>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
              <div className="services__content">
                <div className="icon d-block fas fa-paper-plane"></div>
                <h3 className="display-3--title mt-1">Graphic Design</h3>
                <p className="lh-lg">
                  Our talented graphic designers bring ideas to life, creating
                  visually captivating designs that resonate with your brand
                  identity and engage your target audience across various
                  platforms.
                </p>
             
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-end">
              <div className="services__pic">
                <img
                  src="images/services/service-1.png"
                  alt="marketing illustration"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-start">
              <div className="services__pic">
                <img
                  src="images/services/service-2.png"
                  alt="web development illustration"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
              <div className="services__content">
                <div className="icon d-block fas fa-code"></div>
                <h3 className="display-3--title mt-1">Web Development</h3>
                <p className="lh-lg">
                  Our team of skilled developers crafts dynamic and
                  user-friendly websites, leveraging the latest technologies to
                  ensure seamless navigation, responsive design, and optimal
                  performance.
                </p>
                
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
              <div className="services__content">
                <div className="icon d-block fas fa-cloud-upload-alt"></div>
                <h3 className="display-3--title mt-1">UI/UX Development</h3>
                <p className="lh-lg">
                  We specialize in crafting intuitive and immersive user
                  interfaces, focusing on delivering exceptional user
                  experiences that enhance customer satisfaction and drive
                  conversions.
                </p>
             
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-end">
              <div className="services__pic">
                <img
                  src="images/services/service-3.png"
                  alt="cloud hosting illustration"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-start">
              <div className="services__pic">
                <img
                  src="images/services/service-2.png"
                  alt="web development illustration"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
              <div className="services__content">
                <div className="icon d-block fas fa-code"></div>
                <h3 className="display-3--title mt-1">
                  Mobile App Development
                </h3>
                <p className="lh-lg">
                  Our mobile app development team builds innovative and
                  feature-rich applications for iOS and Android platforms,
                  enabling you to reach and engage with your customers on their
                  preferred devices.
                </p>
             
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
              <div className="services__content">
                <div className="icon d-block fas fa-paper-plane"></div>
                <h3 className="display-3--title mt-1">Wordpress Design</h3>
                <p className="lh-lg">
                  We offer customized WordPress solutions, combining
                  eye-catching design elements with powerful functionality to
                  create websites that are easy to manage and highly scalable.
                </p>
              
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-end">
              <div className="services__pic">
                <img
                  src="images/services/service-1.png"
                  alt="marketing illustration"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-start">
              <div className="services__pic">
                <img
                  src="images/services/service-2.png"
                  alt="web development illustration"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
              <div className="services__content">
                <div className="icon d-block fas fa-code"></div>
                <h3 className="display-3--title mt-1">Digital Marketing</h3>
                <p className="lh-lg">
                  Our digital marketing experts utilize data-driven strategies
                  to boost your online presence, increase brand visibility, and
                  drive targeted traffic to your website, ensuring maximum ROI
                  and business growth.
                </p>
              
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
              <div className="services__content">
                <div className="icon d-block fas fa-cloud-upload-alt"></div>
                <h3 className="display-3--title mt-1">Data Science</h3>
                <p className="lh-lg">
                  Our data science team leverages advanced analytics and machine
                  learning to extract actionable insights from complex datasets,
                  driving innovation and informing decision-making for our
                  company's growth.
                </p>
              
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-end">
              <div className="services__pic">
                <img
                  src="images/services/service-3.png"
                  alt="cloud hosting illustration"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-start">
              <div className="services__pic">
                <img
                  src="images/services/service-2.png"
                  alt="web development illustration"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
              <div className="services__content">
                <div className="icon d-block fas fa-code"></div>
                <h3 className="display-3--title mt-1">Hire Remote Developer</h3>
                <p className="lh-lg">
                  When you choose to hire a remote developer, you unlock a world
                  of talent and expertise without the limitations of
                  geographical boundaries. Our remote developers are highly
                  skilled professionals who can seamlessly integrate into your
                  team, offering their expertise in software development, web
                  development, and other IT domains. Enjoy the flexibility,
                  cost-effectiveness, and access to a diverse talent pool by
                  hiring remote developers who can work remotely and deliver
                  exceptional results.
                </p>
            
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
              <div className="services__content">
                <div className="icon d-block fas fa-cloud-upload-alt"></div>
                <h3 className="display-3--title mt-1">Hire Remote Team</h3>
                <p className="lh-lg">
                  Building a remote team brings numerous benefits to your
                  organization. By assembling a remote team, you gain access to
                  a broader talent pool, including professionals with diverse
                  skill sets and backgrounds. This allows you to scale your team
                  according to project requirements, improve productivity, and
                  reduce costs associated with traditional in-house hiring.
                  Collaborate with our remote teams specialized in IT and
                  software development to drive innovation, achieve project
                  milestones, and meet your business goals effectively.
                </p>
              
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-end">
              <div className="services__pic">
                <img
                  src="images/services/service-3.png"
                  alt="cloud hosting illustration"
                  className="img-fluid"
                />
              </div>
            </div>
          </div> */}
        </div>
      </section>

      {/* <!-- ////////////////////////////////////////////////////////////////////////////////////////////////
                               START SECTION 5 - THE TESTIMONIALS  
/////////////////////////////////////////////////////////////////////////////////////////////////////--> */}
      {/* <section id="testimonials" className="testimonials">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#fff"
            fill-opacity="1"
            d="M0,96L48,128C96,160,192,224,288,213.3C384,203,480,117,576,117.3C672,117,768,203,864,202.7C960,203,1056,117,1152,117.3C1248,117,1344,203,1392,245.3L1440,288L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
          ></path>
        </svg>
        <div className="container">
          <div className="row text-center text-white">
            <h1 className="display-3 fw-bold">Testimonials</h1>
            <hr style={{ width: "100px", height: "3px" }} className="mx-auto" />
            <p className="lead pt-1">what our clients are saying</p>
          </div>

          <div className="row align-items-center">
            <div
              id="carouselExampleCaptions"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="testimonials__card">
                    <p className="lh-lg">
                      <i className="fas fa-quote-left"></i>
                      Patrix LLC stands out as a strategic partner that
                      understands the tech landscape. Their web development
                      finesse streamlined our user experience, and their
                      marketing strategies helped us reach a wider market. Their
                      results-driven approach and professionalism make them our
                      top choice for digital solutions
                      <i className="fas fa-quote-right"></i>
                      <div className="ratings p-1">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                      </div>
                    </p>
                  </div>
                  <div className="testimonials__picture">
                    <img
                      src="images/testimonials/client-1.jpg"
                      alt="client-1 picture"
                      className="rounded-circle img-fluid"
                    />
                  </div>
                  <div className="testimonials__name">
                    <h3>Michael Smith</h3>
                    <p className="fw-light">Director of Apex Innovations</p>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="testimonials__card">
                    <p className="lh-lg">
                      <i className="fas fa-quote-left"></i>
                      Patrix LLC's commitment to sustainability aligned
                      perfectly with our brand values. They not only designed an
                      eco-friendly website but also utilized innovative
                      marketing tactics to attract our niche audience. Their
                      personalized approach and attention to detail set them
                      apart – a true partner in our journey
                      <i className="fas fa-quote-right"></i>
                      <div className="ratings p-1">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                      </div>
                    </p>
                  </div>
                  <div className="testimonials__picture">
                    <img
                      src="images/testimonials/client-2.jpg"
                      alt="client-2 picture"
                      className="rounded-circle img-fluid"
                    />
                  </div>
                  <div className="testimonials__name">
                    <h3>Emily Carter</h3>
                    <p className="fw-light">Founder of EcoFusion Lifestyle</p>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="testimonials__card">
                    <p className="lh-lg">
                      <i className="fas fa-quote-left"></i>
                      Working with Patrix LLC has been a game-changer for our
                      business. Their web development team brought our vision to
                      life with a sleek and functional website, while their
                      marketing expertise significantly boosted our online
                      engagement. Patrix LLC is now an integral part of our
                      growth strategy.
                      <i className="fas fa-quote-right"></i>
                      <div className="ratings p-1">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                      </div>
                    </p>
                  </div>
                  <div className="testimonials__picture">
                    <img
                      src="images/testimonials/client-3.jpg"
                      alt="client-3 picture"
                      className="rounded-circle img-fluid"
                    />
                  </div>
                  <div className="testimonials__name">
                    <h3>Sarah Johnson</h3>
                    <p className="fw-light">CEO of TechTrend Solutions</p>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="testimonials__card">
                    <p className="lh-lg">
                      <i className="fas fa-quote-left"></i>
                      Patrix LLC exceeded my expectations in every aspect. From
                      crafting an enticing website that showcases our offerings
                      beautifully, to driving more customers through targeted
                      marketing campaigns, their team's dedication is evident.
                      Thanks to Patrix LLC, our online presence has transformed
                      into a revenue-generating asset.
                      <i className="fas fa-quote-right"></i>
                      <div className="ratings p-1">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                      </div>
                    </p>
                  </div>
                  <div className="testimonials__picture">
                    <img
                      src="images/testimonials/client-4.jpg"
                      alt="client-4 picture"
                      className="rounded-circle img-fluid"
                    />
                  </div>
                  <div className="testimonials__name">
                    <h3>Mark Davis</h3>
                    <p className="fw-light">Owner of Culinary Delights</p>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button
                  className="btn btn-outline-light fas fa-long-arrow-alt-left"
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide="prev"
                ></button>
                <button
                  className="btn btn-outline-light fas fa-long-arrow-alt-right"
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide="next"
                ></button>
              </div>
            </div>
          </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#fff"
            fill-opacity="1"
            d="M0,96L48,128C96,160,192,224,288,213.3C384,203,480,117,576,117.3C672,117,768,203,864,202.7C960,203,1056,117,1152,117.3C1248,117,1344,203,1392,245.3L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </section> */}

      {/* <!-- /////////////////////////////////////////////////////////////////////////////////////////////////
                       START SECTION 6 - THE FAQ 
//////////////////////////////////////////////////////////////////////////////////////////////////////--> */}
      <section id="faq" className="faq">
        <div className="container">
          <div className="row text-center">
            <h1 className="display-3 fw-bold text-uppercase">faq</h1>
            <div className="heading-line"></div>
            <p className="lead">
              frequently asked questions, get knowledge befere hand
            </p>
          </div>
          {/* <!-- ACCORDION CONTENT  --> */}
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="accordion" id="accordionExample">
                {/* <!-- ACCORDION ITEM 1 --> */}
                <div className="accordion-item shadow mb-3">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      What services does your IT and software company offer?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      We offer a wide range of services including IT
                      infrastructure setup and maintenance, custom software
                      development, graphic design, web development, mobile app
                      development, and digital marketing solutions. We strive to
                      be your one-stop destination for all your technology
                      needs.
                    </div>
                  </div>
                </div>
                {/* <!-- ACCORDION ITEM 2 --> */}
                <div className="accordion-item shadow mb-3">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      How long does it typically take to develop a custom
                      software solution?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      The development timeline depends on the complexity and
                      scope of the project. We work closely with our clients to
                      understand their requirements and provide an estimated
                      timeline. On average, it can take several weeks to several
                      months to complete a custom software development project.
                    </div>
                  </div>
                </div>
                {/* <!-- ACCORDION ITEM 3 --> */}
                <div className="accordion-item shadow mb-3">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Can you help with graphic design for branding purposes?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Absolutely! Our graphic design services cover various
                      aspects of branding, including logo design, marketing
                      collateral, brand identity development, and visual assets
                      creation. We work closely with clients to understand their
                      brand vision and deliver designs that align with their
                      brand identity and goals.
                    </div>
                  </div>
                </div>
                {/* <!-- ACCORDION ITEM 4 --> */}
                <div className="accordion-item shadow mb-3">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      What is the importance of responsive web design?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Responsive web design is crucial in today's mobile-driven
                      world. It ensures that your website adapts and displays
                      properly on different devices and screen sizes, providing
                      a seamless user experience. A responsive website not only
                      improves user engagement but also helps with search engine
                      optimization and overall online visibility..
                    </div>
                  </div>
                </div>
                <div className="accordion-item shadow mb-3">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      How can digital marketing benefit my business?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Digital marketing plays a vital role in expanding your
                      online presence, reaching a wider audience, and driving
                      targeted traffic to your website. It encompasses various
                      strategies like search engine optimization (SEO), social
                      media marketing, pay-per-click (PPC) advertising, and
                      content marketing. Effective digital marketing can boost
                      brand awareness, generate leads, and ultimately increase
                      conversions and business growth.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- /////////////////////////////////////////////////////////////////////////////////////////////////////////
              START SECTION 8 - GET STARTED  
/////////////////////////////////////////////////////////////////////////////////////////////////////////--> */}

      <ContactForm />
    </>
  );
};

export default Home;
