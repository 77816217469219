import "./portfolioWebs.scss";
import { Helmet } from "react-helmet";

const QubicLab = () => {
  return (
    <>
      <Helmet>
        <title>Qubic Lab | Expert App Development Solutions | ADATECK </title>
        {/* <meta
          name="description"
          content="Learn more about ADATECK, a leader in backend and frontend app development. Our dedicated team of professionals is committed to delivering cutting-edge, customized solutions for your business. Explore our mission, values, and the expertise that sets us apart in the tech industry. Contact us to elevate your digital presence with our innovative app development services."
        /> */}
      </Helmet>
      <div style={{ marginTop: "140px", marginBottom: "100px" }}>
        <div className="row text-center mb-4">
          <h1 className="display-3 fw-bold text-uppercase">Qubic Lab</h1>
          <div className="heading-line"></div>
        </div>

        <div className="container-lg">
          <div className="row align-items-center snipcss-ZKJbZ">
            <div className="col-12 col-lg-6 ">
              <div className="who-we-contant mt-s">
                <div className="promo-section">
                  <h3 className="special-head ">QUBIC LAB!</h3>
                </div>
                <h4 className="d-blue fadeInUp" data-wow-delay="0.3s">
                  Collaborative Mining Pools for Large-Scale Miners
                </h4>
                <div className="list-wrap align-items-center">
                  <div className="row">
                    <p>
                      Qubic Lab offers a comprehensive platform tailored for
                      large-scale cryptocurrency miners, facilitating
                      collaboration within specialized mining pools. These pools
                      enable miners to aggregate their substantial computational
                      power, significantly reducing individual risks while
                      ensuring more consistent and reliable results. By
                      contributing significant hashing power, miners
                      collectively enhance their chances of solving complex AI
                      models, leading to a steady and lucrative income stream.
                      <br />
                      <br />
                      When a solution is found, it is submitted to the pool for
                      rigorous validation before being recorded on the
                      blockchain under the pool's ID, ensuring both transparency
                      and security. This collaborative approach not only
                      optimizes mining efficiency but also fosters a community
                      of shared resources and collective problem-solving. Miners
                      benefit from the synergy of combined efforts, ultimately
                      enhancing profitability and maintaining a competitive edge
                      in the dynamic cryptocurrency mining landscape. Through
                      Qubic Lab, large-scale miners can achieve greater
                      operational efficiency and financial stability.
                    </p>
                    <a
                      href="https://qubic.minerlab.io/"
                      className="link-style"
                      target="_blank"
                    >
                      Visit QUBIC LAB
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div
                className="welcome-meter about-sec-wrapper wow fadeInUp style-Kojfk"
                data-wow-delay="0.4s"
                id="style-Kojfk"
              >
                <img src="images/miner-lab.jpeg" className="about-i" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QubicLab;
