import React from "react";
import { Helmet } from "react-helmet";

const WordPressDesign = () => {
  return (
    <>
      <Helmet>
        <title>Professional WordPress Design Services | ADATECK </title>
        <meta
          name="description"
          content="Boost your online presence with ADATECK's professional WordPress design services. Our expert designers create visually appealing, responsive, and user-friendly WordPress websites tailored to your business needs. From theme customization to plugin integration, we deliver innovative solutions to enhance your site's functionality and aesthetics. Contact us today to start your WordPress project and achieve your digital goals."
        />
      </Helmet>
      <div style={{ marginTop: "110px", marginBottom: "50px" }}>
        <section className="py-5">
          <div className="container">
            <div className="row text-center">
              <h1 className="display-3 fw-bold text-capitalize">
                WordPress Design
              </h1>
              <div className="heading-line"></div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <h3>Custom WordPress Themes</h3>
                <p>
                  We specialize in creating custom WordPress themes tailored to
                  your specific needs. Our design team crafts unique and
                  eye-catching themes that reflect your brand identity and
                  resonate with your target audience.
                </p>
              </div>
              <div className="col-lg-6">
                <h3>WordPress Plugin Development</h3>
                <p>
                  Our developers are experienced in building custom WordPress
                  plugins to extend the functionality of your website. Whether
                  you need a simple feature or a complex solution, we can
                  develop plugins that integrate seamlessly with your WordPress
                  site.
                </p>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-6">
                <h3>Responsive WordPress Design</h3>
                <p>
                  We prioritize responsive design to ensure that your WordPress
                  website looks and performs optimally on all devices. Our
                  designs are fluid and adaptive, providing an exceptional user
                  experience across desktops, tablets, and smartphones.
                </p>
              </div>
              <div className="col-lg-6">
                <h3>WordPress Theme Customization</h3>
                <p>
                  If you already have a WordPress theme but need customization,
                  we've got you covered. Our team can modify existing themes to
                  align with your brand identity, add new features, or optimize
                  performance according to your requirements.
                </p>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-6">
                <h3>WordPress Maintenance and Support</h3>
                <p>
                  We offer comprehensive WordPress maintenance and support
                  services to keep your website running smoothly. From regular
                  updates to security monitoring and troubleshooting, we ensure
                  that your WordPress site remains secure, up-to-date, and
                  optimized for performance.
                </p>
              </div>
              <div className="col-lg-6">
                <h3>WordPress Migration</h3>
                <p>
                  Planning to migrate your website to WordPress? We can help.
                  Our team handles the entire migration process seamlessly,
                  ensuring minimal downtime and preserving your website's
                  integrity and functionality throughout the transition.
                </p>
              </div>
            </div>
            {/* FAQ Section */}
            <section id="faq" className="faq mt-5">
              <div className="container">
                <div className="row text-center">
                  <h1 className="display-3 fw-bold text-uppercase">FAQs</h1>
                  <div className="heading-line"></div>
                  <p className="lead">
                    Frequently Asked Questions about WordPress Design and
                    Adateck
                  </p>
                </div>
                <div className="row mt-5">
                  <div className="col-md-12">
                    <div className="accordion" id="accordionExample">
                      {/* FAQ 1 */}
                      <div className="accordion-item shadow mb-3">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            What types of WordPress websites can Adateck design?
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            Adateck can design various types of WordPress
                            websites, including blogs, corporate websites,
                            e-commerce stores, portfolio sites, and more. We
                            tailor our designs to meet your specific needs and
                            objectives, regardless of the website type.
                          </div>
                        </div>
                      </div>
                      {/* FAQ 2 */}
                      <div className="accordion-item shadow mb-3">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            How long does it take to design a custom WordPress
                            theme?
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            The timeline for designing a custom WordPress theme
                            depends on various factors such as complexity,
                            features, and client feedback. We work closely with
                            you to understand your requirements and provide an
                            estimated timeline for the design process.
                          </div>
                        </div>
                      </div>
                      {/* FAQ 3 */}
                      <div className="accordion-item shadow mb-3">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            Can you customize an existing WordPress theme?
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            Yes, we offer WordPress theme customization services
                            to modify existing themes according to your specific
                            requirements. Whether you need design tweaks,
                            additional features, or performance optimization, we
                            can customize the theme to suit your needs.
                          </div>
                        </div>
                      </div>
                      {/* FAQ 4 */}
                      <div className="accordion-item shadow mb-3">
                        <h2 className="accordion-header" id="headingFour">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            Do you provide ongoing support for WordPress
                            websites?
                          </button>
                        </h2>
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFour"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            Yes, we offer comprehensive support and maintenance
                            services for WordPress websites. Our team ensures
                            that your website remains secure, up-to-date, and
                            optimized for performance through regular updates,
                            backups, and technical assistance.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </>
  );
};

export default WordPressDesign;
