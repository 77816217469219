import React from "react";
import { Helmet } from "react-helmet";

const HireRemoteTeam = () => {
  return (
    <>
      <Helmet>
        <title>Hire Remote Team | Find Top Talent Worldwide | ADATECK </title>
        <meta
          name="description"
          content="Hire a remote team with [Your Company Name]. Explore skilled professionals across the globe ready to enhance your projects. Contact us today to build your remote dream team!"
        />
      </Helmet>
      <div style={{ marginTop: "110px", marginBottom: "50px" }}>
        <section className="py-5">
          <div className="container">
            <div className="row text-center">
              <h1 className="display-3 fw-bold text-capitalize">
                Hire Remote Team
              </h1>
              <div className="heading-line"></div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <h3>Benefits of Hiring a Remote Team</h3>
                <p>
                  Hiring a remote team offers numerous benefits including access
                  to global talent pool, cost savings on office space and
                  infrastructure, flexibility in hiring resources based on
                  project needs, increased productivity due to flexible work
                  hours, and diversity in perspectives and ideas.
                </p>
              </div>
              <div className="col-lg-6">
                <h3>Our Remote Team Services</h3>
                <p>
                  Our remote team consists of skilled professionals in various
                  domains including software development, web design, digital
                  marketing, data science, and more. We offer flexible
                  engagement models such as dedicated teams, project-based
                  outsourcing, and staff augmentation to meet your specific
                  requirements and ensure project success.
                </p>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-6">
                <h3>How It Works</h3>
                <p>
                  Hiring a remote team is simple and straightforward. You
                  provide us with your project requirements, and we assemble a
                  team of remote professionals with the right skills and
                  experience to meet your needs. We ensure seamless
                  communication, collaboration, and project management to
                  deliver high-quality results on time and within budget.
                </p>
              </div>
              <div className="col-lg-6">
                <h3>Why Choose Us?</h3>
                <p>
                  We have a proven track record of delivering successful
                  projects with remote teams. Our transparent and client-centric
                  approach, combined with our commitment to quality and
                  innovation, sets us apart. When you hire our remote team, you
                  can trust us to deliver exceptional results and help you
                  achieve your business objectives.
                </p>
              </div>
            </div>
            {/* FAQ Section */}
            <section id="faq" className="faq mt-5">
              <div className="container">
                <div className="row text-center">
                  <h1 className="display-3 fw-bold text-uppercase">FAQs</h1>
                  <div className="heading-line"></div>
                  <p className="lead">
                    Frequently Asked Questions about Hiring a Remote Team
                  </p>
                </div>
                <div className="row mt-5">
                  <div className="col-md-12">
                    <div className="accordion" id="accordionExample">
                      {/* FAQ 1 */}
                      <div className="accordion-item shadow mb-3">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            What are the advantages of hiring a remote team?
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            Hiring a remote team offers numerous benefits
                            including access to global talent pool, cost savings
                            on office space and infrastructure, flexibility in
                            hiring resources based on project needs, increased
                            productivity due to flexible work hours, and
                            diversity in perspectives and ideas.
                          </div>
                        </div>
                      </div>
                      {/* FAQ 2 */}
                      <div className="accordion-item shadow mb-3">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            How do you ensure communication and collaboration
                            with a remote team?
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            We use various communication and collaboration tools
                            such as video conferencing, instant messaging,
                            project management platforms, and version control
                            systems to ensure seamless interaction and
                            coordination with our remote team members. We
                            establish clear communication channels and regular
                            checkpoints to keep you informed about the project
                            progress and address any concerns or issues
                            promptly.
                          </div>
                        </div>
                      </div>
                      {/* FAQ 3 */}
                      <div className="accordion-item shadow mb-3">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            Can I hire a dedicated remote team for my project?
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            Yes, you can hire a dedicated remote team
                            exclusively for your project. We offer flexible
                            engagement models tailored to your specific
                            requirements, whether you need a dedicated
                            development team, project-based outsourcing, or
                            staff augmentation. Our goal is to provide you with
                            the right resources and expertise to ensure project
                            success and achieve your business objectives.
                          </div>
                        </div>
                      </div>
                      {/* FAQ 4 */}
                      <div className="accordion-item shadow mb-3">
                        <h2 className="accordion-header" id="headingFour">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            How do you ensure the quality of work delivered by
                            your remote team?
                          </button>
                        </h2>
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFour"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            We maintain strict quality control measures
                            throughout the project lifecycle to ensure the
                            highest standards of workmanship and deliverables.
                            Our remote team members undergo rigorous screening
                            and selection processes to ensure they possess the
                            necessary skills, experience, and professionalism
                            required for the job. We also conduct regular
                            performance evaluations, code reviews, and quality
                            assurance tests to monitor progress, identify
                            issues, and maintain consistency and quality in our
                            deliverables.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>{" "}
    </>
  );
};

export default HireRemoteTeam;
