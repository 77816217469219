import "./portfolioWebs.scss";
import { Helmet } from "react-helmet";

const ExpressTriage = () => {
  return (
    <>
      <Helmet>
        <title>
          Express Triage | Expert App Development Solutions | ADATECK{" "}
        </title>
        {/* <meta
          name="description"
          content="Learn more about ADATECK, a leader in backend and frontend app development. Our dedicated team of professionals is committed to delivering cutting-edge, customized solutions for your business. Explore our mission, values, and the expertise that sets us apart in the tech industry. Contact us to elevate your digital presence with our innovative app development services."
        /> */}
      </Helmet>
      <div style={{ marginTop: "140px", marginBottom: "100px" }}>
        <div className="row text-center mb-4">
          <h1 className="display-3 fw-bold text-uppercase">Express Triage</h1>
          <div className="heading-line"></div>
        </div>
        <div className="container-lg" style={{ marginBottom: "100px" }}>
          <div className="row align-items-center snipcss-ZKJbZ">
            <div className="col-12 col-lg-6">
              <div
                className="welcome-meter about-sec-wrapper wow fadeInUp style-Kojfk"
                data-wow-delay="0.4s"
                id="style-Kojfk"
              >
                <img
                  src="images/express-triage.jpg"
                  className="about-i"
                  alt=""
                />
              </div>
            </div>
            <div className="col-12 col-lg-6 ">
              <div className="who-we-contant mt-s">
                <div className="promo-section">
                  <h3 className="special-head ">EXPRESS TRIAGE!</h3>
                </div>
                <h4 className="d-blue fadeInUp" data-wow-delay="0.3s">
                  Welcome to Express Triage
                </h4>
                <div className="list-wrap align-items-center">
                  <div className="row">
                    <p>
                      Welcome to Express Triage, your trusted ally in
                      contemporary healthcare. Discover our mission, values, and
                      the dedicated team driving our groundbreaking platform.
                      <br /> <br />
                      At Express Triage, our mission is to transform the
                      healthcare landscape by harnessing technology to deliver
                      convenient, accessible, and personalized care to patients
                      around the globe. We are committed to ensuring that
                      everyone has timely access to quality healthcare.
                      <br />
                      <span className="bold-heading">
                        {" "}
                        Streamlined Care:
                      </span>{" "}
                      <br />
                      Express Triage revolutionizes the appointment process by
                      seamlessly integrating urgent care, telehealth, and
                      emergency room services. By pre-collecting essential
                      information, we expedite appointments, minimizing wait
                      times and ensuring you receive the care you need promptly.
                      <br />
                      <span className="bold-heading">
                        {" "}
                        Efficient Triage:
                      </span>{" "}
                      <br />
                      Our advanced platform optimizes the triage process,
                      directing patients to the most appropriate care options.
                      This enhances the check-in procedure and overall
                      healthcare delivery, ensuring that patients are swiftly
                      attended to by the right healthcare professionals.
                      <br />
                      <span className="bold-heading">
                        {" "}
                        Timely Access to Care:
                      </span>{" "}
                      <br />
                      By adhering to our care recommendations, you can expect
                      timely and efficient attention, significantly improving
                      your healthcare experience. Express Triage guarantees that
                      your health needs are met quickly and effectively.
                      <br />
                      <span className="bold-heading">
                        {" "}
                        Safeguard Your Health with Routine Checkups:
                      </span>{" "}
                      <br />
                      Routine health checkups are crucial for maintaining your
                      well-being and preventing potential health issues from
                      escalating. Express Triage simplifies these checkups,
                      making them convenient and accessible.
                      <br />
                      <span className="bold-heading">
                        {" "}
                        Comprehensive Health Evaluation:{" "}
                      </span>{" "}
                      Receive an in-depth analysis of your health status. -
                      <span className="bold-heading">
                        {" "}
                        Tailored Health Recommendations:{" "}
                      </span>{" "}
                      Obtain personalized advice to maintain and enhance your
                      health.
                      <br />
                      <span className="bold-heading">
                        {" "}
                        Early Identification of Health Risks:{" "}
                      </span>{" "}
                      Detect and address potential health concerns early.
                      <span className="bold-heading">
                        {" "}
                        Convenience and Accessibility:{" "}
                      </span>{" "}
                      Enjoy the ease of scheduling and attending checkups from
                      the comfort of your home.
                      <br /> <br />
                      Join Express Triage and embrace a new era of healthcare
                      that prioritizes your health, convenience, and peace of
                      mind.
                    </p>
                    <a
                      href="https://www.expresstriage.com"
                      className="link-style"
                      target="_blank"
                    >
                      Visit EXPRESS TRIAGE
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpressTriage;
