import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Home from "./routes/home/home";
import HeaderFooter from "./routes/headerfooter/header-footer";
import About from "./routes/about/about";
import Contact from "./routes/contact/contact";
import Faq from "./routes/faq/faq";
import Services from "./routes/services/services";
import Careers from "./routes/careers/careers";
import Job from "./routes/jobs/job";
import Blogs from "./routes/blogs/blogs";
import Blog from "./routes/blog/blog";
import WebDevelopment from "./routes/web-development/web-development";
import GraphicsDesign from "./routes/graphics-design/graphics-design";
import MobileAppDevelopment from "./routes/mobile-app-development/mobile-app-development";
import UIUXDevelopment from "./routes/uiux-development/uiux-development";
import DataScience from "./routes/data-science/data-science";
import HireRemoteDeveloper from "./routes/hire-remote-developer/hire-remote-developer";
import HireRemoteTeam from "./routes/hire-remote-team/hire-remote-team";
import WordPressDesign from "./routes/wordpress-design/wordpress-design";
import DigitalMarketing from "./routes/digital-marketing/digital-marketing";
import { useEffect, useRef } from "react";
import OurPortfolio from "./routes/our portfolio/ourPortfolio";
import Cvvlogs from "./routes/portfolioWebs/cvvlogs";
import PrevisitReview from "./routes/portfolioWebs/previsit";
import ExpressTriage from "./routes/portfolioWebs/expressTriage";
import QubicLab from "./routes/portfolioWebs/qubicLab";

const ScrollToTop = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const unlisten = navigate((location) => {
      if (typeof window !== "undefined") {
        window.scrollTo(0, 0);
      }
    });

    return () => {
      unlisten();
    };
  }, [navigate]);

  return null;
};

const App = () => {
  document.title = "ADATECK";
  const location = useLocation();
  const prevLocationRef = useRef();

  useEffect(() => {
    if (
      prevLocationRef.current &&
      prevLocationRef.current.pathname !== location.pathname
    ) {
      // Route has changed
      window.scrollTo(0, 0);
    }

    // Update previous location
    prevLocationRef.current = location;
  }, [location]);
  return (
    <Routes>
      <Route path="/" element={<HeaderFooter />}>
        <Route index element={<Home />} />
        <Route path="about-us" element={<About />} />
        <Route path="services" element={<Services />} />
        <Route path="faq" element={<Faq />} />
        <Route path="contact-us" element={<Contact />} />
        <Route path="careers" element={<Careers />} />
        <Route path="job" element={<Job />} />
        <Route path="blogs" element={<Blogs />} />
        <Route path="blog" element={<Blog />} />
        <Route path="web-development" element={<WebDevelopment />} />
        <Route path="graphics-design" element={<GraphicsDesign />} />
        <Route
          path="mobile-app-development"
          element={<MobileAppDevelopment />}
        />
        <Route path="ui-ux-development" element={<UIUXDevelopment />} />
        <Route path="data-science" element={<DataScience />} />
        <Route path="hire-remote-developer" element={<HireRemoteDeveloper />} />
        <Route path="hire-remote-team" element={<HireRemoteTeam />} />
        <Route path="wordpress-design" element={<WordPressDesign />} />
        <Route path="digital-marketing" element={<DigitalMarketing />} />
        <Route path="our-portfolio" element={<OurPortfolio />} />
        <Route path="cvvlogs" element={<Cvvlogs />} />
        <Route path="previsitreview" element={<PrevisitReview />} />
        <Route path="express-triage" element={<ExpressTriage />} />
        <Route path="qubic-lab" element={<QubicLab />} />
      </Route>
    </Routes>
  );
};

export default App;
