import "./job.scss";

const Job = () => {
  return (
    <div style={{ marginTop: "110px", marginBottom: "50px", color: "black" }}>
      {" "}
      <div className="container-lg pt-5 pb-5 ">
        <div className="row justify-content-between">
          <div className="col-lg-8 col-md-9 col-sm-12 job-left-column">
            <h1 className="job-title">Back End Engineer - PHP</h1>
            <h2 className="job-second-title">Job Overview</h2>
            <p className="job-description">
              We are looking for an experienced PHP developer with a strong
              technical background to join our fantastic core team. The team
              consists of five PHP developers and three QA members working
              together.
            </p>
            <h2 className="job-second-title">Job Description</h2>
            <p className="job-description">
              {" "}
              <ul className="job-list">
                <li>
                  In this role, you will be responsible for designing,
                  developing, deploying, and maintaining web applications, and
                  API end-points
                </li>
                <li>
                  You will work closely with a team of developers, designers,
                  and product managers to deliver high-quality software
                  solutions
                </li>
                <li>
                  Improving the existing codebase as well as testing, reviewing,
                  and debugging code will also form part of your
                  responsibilities
                </li>
              </ul>
            </p>
            <h2 className="job-second-title">Job Specifications</h2>
            <p className="job-description">
              {" "}
              <ul className="job-list">
                <li>
                  Strong understanding of software development principles and
                  best practices
                </li>
                <li>PHP framework experience</li>
                <li>
                  Proficient in PHP programming language and Laravel framework
                </li>
                <li>
                  Knowledge of database technologies, such as SQL and NoSQL
                </li>
                <li>
                  Understanding of software design patterns and architectures
                </li>
                <li>
                  Familiarity with front-end technologies such as HTML, CSS, and
                  JavaScript
                </li>
                <li>Excellent problem-solving and analytical skills</li>
                <li>Strong communication and collaboration skills</li>
                <li>
                  Ability to write clean, maintainable, and efficient PHP
                  Laravel code
                </li>
                <li>
                  Experience with agile software development methodologies
                </li>
                <li>Strong debugging and troubleshooting skills</li>
                <li>Ability to work independently or in a team environment</li>
                <li>Ability to manage multiple tasks and priorities</li>
                <li>
                  Ability to learn and adapt to new technologies and programming
                  languages
                </li>
                <li>
                  Ability to take ownership of PHP Laravel projects and
                  deliverables
                </li>
                <li>Ability to work in a fast-paced and dynamic environment</li>
                <li>
                  This position offers the flexibility of both hybrid and remote
                  work options
                </li>
              </ul>
            </p>
            <h2 className="job-second-title">Perks and Benefits</h2>
            <div className="perks-container">
              <div className="perk-item">
                <div
                  className="perk-top"
                  style={{ backgroundColor: "#FDEDF4" }}
                >
                  <i className="fa-solid fa-house-medical fa-2xl"></i>
                </div>{" "}
                <br />
                <div className="perk-bottom">Medical Insurance</div>
              </div>
              <div className="perk-item">
                <div
                  className="perk-top"
                  style={{ backgroundColor: "#FFF5EE" }}
                >
                  <i class="fa-solid fa-address-card fa-2xl"></i>{" "}
                </div>{" "}
                <br />
                <div className="perk-bottom">Permanent Employment Status</div>
              </div>
              <div className="perk-item">
                <div
                  className="perk-top"
                  style={{ backgroundColor: "#E6FBFB" }}
                >
                  <i class="fa-solid fa-person-through-window fa-2xl"></i>{" "}
                </div>{" "}
                <br />
                <div className="perk-bottom">Provident Fund</div>
              </div>
              <div className="perk-item">
                <div
                  className="perk-top"
                  style={{ backgroundColor: "#E7F7FC" }}
                >
                  <i class="fa-solid fa-briefcase fa-2xl"></i>{" "}
                </div>{" "}
                <br />
                <div className="perk-bottom">Internal Job Opportunities</div>
              </div>
              <div className="perk-item">
                <div
                  className="perk-top"
                  style={{ backgroundColor: "#F0F1FF" }}
                >
                  <i class="fa-solid fa-calendar-days fa-2xl"></i>{" "}
                </div>{" "}
                <br />
                <div className="perk-bottom">Annual Leaves</div>
              </div>
              <div className="perk-item">
                <div
                  className="perk-top"
                  style={{ backgroundColor: "#F6EEFD" }}
                >
                  <i class="fa-solid fa-money-check-dollar fa-2xl"></i>{" "}
                </div>{" "}
                <br />
                <div className="perk-bottom">Leaves Encashment</div>
              </div>
              <div className="perk-item">
                <div
                  className="perk-top"
                  style={{ backgroundColor: "#FFF8E5" }}
                >
                  <i class="fa-solid fa-handshake fa-2xl"></i>{" "}
                </div>{" "}
                <br />
                <div className="perk-bottom">Referral Bonus</div>
              </div>
              <div className="perk-item">
                <div
                  className="perk-top"
                  style={{ backgroundColor: "#FDEDF4" }}
                >
                  <i class="fa-solid fa-van-shuttle fa-2xl"></i>{" "}
                </div>{" "}
                <br />
                <div className="perk-bottom">Company Pick & Drop</div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 job-right-column">
            <div className="apply-button">Apply Now</div>
            <div className="job-main-points">
              <div className="job-main-point">
                <div className="main-point-left">
                  <i class="fa-solid fa-person fa-2xl"></i>
                </div>
                <div className="main-point-right">
                  <div className="main-point-right-top">02</div>
                  <div className="main-point-right-bottom">
                    Number of Vacancies
                  </div>
                </div>
              </div>{" "}
              <div className="job-main-point">
                <div className="main-point-left">
                  <i class="fa-solid fa-clock fa-xl"></i>{" "}
                </div>
                <div className="main-point-right">
                  <div className="main-point-right-top">09</div>
                  <div className="main-point-right-bottom">Working Hours </div>
                </div>
              </div>{" "}
              <div className="job-main-point">
                <div className="main-point-left">
                  <i class="fa-solid fa-location-dot fa-xl"></i>{" "}
                </div>
                <div className="main-point-right">
                  <div className="main-point-right-top">
                    Karachi, Lahore, Islamabad
                  </div>
                  <div className="main-point-right-bottom">Location </div>
                </div>
              </div>{" "}
              <div className="job-main-point">
                <div className="main-point-left">
                  <i class="fa-solid fa-clock-rotate-left fa-xl"></i>{" "}
                </div>
                <div className="main-point-right">
                  <div className="main-point-right-top">Morning</div>
                  <div className="main-point-right-bottom">Job Shift </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default Job;
